import { SettingIcon } from "./../../../assets/images/index"
function getHeader(
  template,
  title,
  searchTitle,
  backFlag = true,
  selfRefresh = false
) {
  switch (template) {
    case 1:
      return {
        header: true,
        title: title,
        titleAlign: "center",
        backButton: true,
        background: "background-white",
        fontColor: "black-font",
        searchBar: false,
        searchTitle: searchTitle,
      }
    case 2:
      return {
        header: true,
        title: title,
        titleAlign: "left",
        backButton: false,
        background: "background-header-red",
        fontColor: "white-font",
        searchBar: true,
        searchTitle: searchTitle,
        selfRefresh: true,
      }
    case 3:
      return {
        header: true,
        title: title,
        titleAlign: "center",
        backButton: backFlag,
        background: "background-header-red",
        fontColor: "white-font",
        searchBar: false,
        iconColor: "white",
      }
    case 4:
      return {
        header: true,
        title: title,
        titleAlign: "center",
        backButton: true,
        background: "background-grey",
        fontColor: "black-font",
        searchBar: false,
        searchTitle: searchTitle,
      }
    case 5:
      return {
        header: true,
        title: title,
        titleAlign: "center",
        backButton: false,
        background: "background-header-red",
        fontColor: "white-font",
        searchBar: false,
        searchTitle: searchTitle,
        rightButton: true,
        rightIcon: SettingIcon,
        rightLink: "/setting",
      }
    case 6:
      return {
        header: true,
        title: title,
        titleAlign: "left",
        backButton: true,
        background: "background-header-red",
        fontColor: "white-font",
        searchBar: false,
        dropDown: true,
        iconColor: "#C4C4C4",
      }
    case 7:
      return {
        header: true,
        title: title,
        titleAlign: "left",
        backButton: true,
        background: "background-header-red",
        fontColor: "white-font",
        searchBar: true,
        searchTitle: searchTitle,
        iconColor: "#C4C4C4",
      }
    case 8:
      return {
        header: true,
        title: title,
        titleAlign: "left",
        backButton: true,
        background: "background-header-red",
        fontColor: "white-font",
        searchBar: false,
        iconColor: "#C4C4C4",
      }
    default:
      return {
        header: false,
      }
  }
}

export default {
  getHeader,
}
