import React, { useState, useEffect } from "react"
import Navigation from "../common/navbar"
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap"
import Room from "./../common/room"
import renderServices from "../../services/render.services"
import Pin from "./../common/pin"

const BiddingRoom = ({ search, setSearch }) => {
  const [navState] = useState({ navState: "/bidding/room" })
  const [bidRoom, setBidRoom] = useState({})
  // const [pin, setPin] = useState(false)
  const [data, setData] = useState({
    pin: false,
    id: "",
    api: "/bid_deposit",
  })

  const getBidRoom = async (search) => {
    // console.log("get bid room ", search);
    const response = await renderServices.biddingRoom(search)
    setBidRoom(response.data)
  }

  const checkTradePinExist = () => {
    const trade_pin = localStorage.getItem("trade_pin")
    if (trade_pin === "" || trade_pin === null) {
      return false
    } else {
      return true
    }
  }
  const openPin = (bidRoom) => {
    setData({
      pin: true,
      name: bidRoom.name,
      api: "/room_with_bid_item",
      link: "/bidding/room/detail/" + bidRoom.id + "/bid",
      room_id: bidRoom.id,
      type: "bid",
      method: "post",
      navLink: navState,
    })
  }
  const closePin = () => {
    setData({
      pin: false,
    })
  }

  useEffect(() => {
    // console.log("use effect ", search);
    getBidRoom(search).catch((e) => {
      console.log(e)
    })
  }, [search])
  function roomListing() {
    return (
      <React.Fragment>
        <Row style={{ backgroundColor: "#f4f4f4" }}>
          <Pin data={data} closePin={closePin} />
          {bidRoom.length > 0 ? (
            bidRoom.map((bidRoom) => {
              return (
                <Col xs={4} key={bidRoom.id} onClick={() => openPin(bidRoom)}>
                  <Room title={bidRoom.name} url={bidRoom.mainMediaUrl} />
                </Col>
              )
            })
          ) : (
            <Col xs={12} style={{ textAlign: "center", marginTop: "2em" }}>
              暂无数据显示
            </Col>
          )}
        </Row>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Navigation />
      <div className="main">
        <div
          className="main-body "
          style={{ marginTop: "1em", borderRadius: "30px 30px 0px 0px" }}
        >
          <Container style={{ marginBottom: "2.5em", overflowX: "hidden" }}>
            {roomListing()}
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BiddingRoom
