import React from "react"
import { Row } from "react-bootstrap"

const Button = ({
  label,
  isSubmitting,
  dirty,
  isValid,
  className,
  ...rest
}) => {
  return (
    <Row className={`custom-button ${className ? className : ""}`}>
      <button type="submit" className="btn" disabled={!isValid || isSubmitting}>
        {isSubmitting ? "提交中" : label}
      </button>
    </Row>
  )
}

export default Button
