import React from "react";
import { Card } from "react-bootstrap";

const CategoryCard = ({ data, customClickEvent }) => {
  return (
    <Card
      onClick={(e) => customClickEvent(e, data.id)}
      key={`category-${data.id}`}
      className="custom-card"
    >
      <Card.Img variant="top" src={data.mainMediaUrl} loading="lazy" />
      <Card.Text className="text-center tiny-font">{data.name}</Card.Text>
    </Card>
  );
};

export default CategoryCard;
