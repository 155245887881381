import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button, Form } from "react-bootstrap"
import Text from "./../common/text"
import { BankOfChina, AliPay, WechatPay } from "./../../assets/images/index"
import renderServices from "../../services/render.services"
import { Formik, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import common from "../../utils/common"

const TopUp = () => {
  const [walletBalance, setWalletBalance] = useState(0)
  const [choice, setChoice] = useState([
    { method: "中国银行", icon: BankOfChina },
    { method: "支付宝", icon: AliPay },
    { method: "微信支付", icon: WechatPay },
  ])
  useEffect(() => {
    getWalletBalance()
  })
  const getWalletBalance = async () => {
    const response = await renderServices.userBalance()
    if (response) setWalletBalance(response.data.wallet_balance)
  }
  const TopUpSchema = Yup.object({
    amount: Yup.number()
      .typeError("充值金额格式必须是数字")
      .required("需要填入充值金额"),
  })

  return (
    <Container className="container-full">
      <Formik
        initialValues={{ amount: "0" }}
        validationSchema={TopUpSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          // onSubmit(values, setFieldError, navigate, setSubmitting);
          common.sweetAlertCustom({
            title: "通知",
            html: `提交中`,
            showCancelButton: false,
            showConfirmButton: false,
          })
        }}
      >
        {({ setFieldValue, errors, isSubmitting, isValid }) => (
          <>
            <Row className="topup-row">
              <Col className="" style={{ padding: 0 }}>
                <Field
                  className="withdrawal_row2_amount"
                  name={"amount"}
                  type={"number"}
                />
              </Col>
              {/* <Col className="col-5" style={{ padding: 0 }}>
                  <Field
                    className="withdrawal_row2_amount"
                    placeholder="请输入提现金额"
                    name="amount"
                    type="number"
                    onChange={(e) => {
                      handleChange(e);
                      let someValue = e.currentTarget.value;
                      calculation(someValue);
                    }}
                  ></Field>
                </Col> */}

              <Col className="col-2">
                <div className="topup-currency">RMB</div>
              </Col>
            </Row>
            <ErrorMessage
              className="text-danger text-left error-message"
              name={"amount"}
              component="div"
              style={{ marginLeft: "0.5em" }}
            />
            <Row className="topup-row-grey">
              <Col>
                <Text
                  value="可用金额 ："
                  className=""
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "18px",
                    alignItems: "center",
                    color: "#878585",
                  }}
                />
              </Col>
              <Col>
                <Text
                  value={`￥ ${walletBalance}`}
                  className="red-font"
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    alignItems: "center",
                  }}
                />
              </Col>
            </Row>
            <Row className="topup-row-grey">
              <Text value="请选择支付方式" />
            </Row>
            <Row className="topup-row-grey">
              <Form>
                {choice.map((item, index) => (
                  <Row className="topup-row-grey-choice">
                    <Col xs={2}>
                      <img src={item.icon} className="topup-icon" alt="" />
                    </Col>
                    <Col
                      key={`default-${index}`}
                      xs={10}
                      className="topup-radio-button"
                    >
                      <Form.Check
                        name="topup-radio"
                        id={`radio-${index}`}
                        type="radio"
                        label={`${item.method}`}
                      />
                    </Col>
                  </Row>
                ))}
              </Form>
            </Row>
            <Row className="topup-row-grey topup-row-button">
              <Col className="topup-col-button">
                <Button
                  className="topup-custom-button"
                  onClick={() => {
                    common.sweetAlertCustom({
                      title: "通知",
                      html: `此服务展示未能开通，请联系客服`,
                      showCancelButton: false,
                      showConfirmButton: false,
                    })
                  }}
                >
                  <Text value="立即充值" />
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </Container>
  )
}

export default TopUp
