import React, { useState, useEffect, useRef } from "react";
import Header from "./../common/header";
import { Container } from "react-bootstrap";
import ProtectedRoute from "../common/protected.route";
import useScreenOrientation from "react-hook-screen-orientation";
import _ from "lodash";
import { Rotate } from "./../../assets/images/index";
import { useNavigate } from "react-router";
import { Flip } from "react-toastify";
import { ToastContainer } from "react-toastify";
import renderServices from "../../services/render.services";
import packageJson from "./../../../package.json";

const style = {
  primary: {
    backgroundColor: "#F4F4F4",
    // position: "absolute",
    overflowY: "unset",
  },
  horizontal: {
    backgroundColor: "white",
    height: "100%",
    minHeight: "200vh",
    display: "flex",
    alignItems: "center",
    overflowY: "unset",
    backgroundImage: `url(${Rotate})`,
    // width: "100vw",
    // height: "100vh",
  },
};

const bubbleStyle = {
  position: "absolute",
  left: 24,
  bottom: 24,
};
function custom_close() {
  window.opener = null;
  // window.open("", "_self");
  // window.close();
  window.open("about:blank", "_self");
  window.close();
}
const checkVersion = async () => {
  const result = await renderServices.getAppVersion();
  if (result) {
    if (result.data != "") {
      if (result.data != packageJson.version) {
        custom_close();
      }
    }
  }
};

const Screen = ({ header, component: Component, publicAuthorize }) => {
  const [title, setTitle] = useState("");
  const [orientation, setOrientation] = useState(0);
  const [search, setSearch] = useState("");
  const screenOrientation = useScreenOrientation();
  const navigate = useNavigate();
  checkVersion();
  useEffect(() => {
    window.addEventListener("touchstart", (e) => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (
      _.includes(
        ["/marketplace", "/profile", "/dashboard"],
        window.location.pathname
      )
    ) {
      setTitle("");
    }
    window.addEventListener("orientationchange", (event) => {
      setOrientation(window.orientation);
    });
  });

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Close toast if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  if (orientation === 0) {
    // if (true) {
    if (
      _.includes(
        ["portrait-primary", "portrait-secondary", undefined],
        // [
        //   "portrait-primary",
        //   "portrait-secondary",
        //   undefined,
        //   "landscape-secondary",
        //   "landscape-primary",
        // ],
        screenOrientation
      )
    )
      return (
        <Container
          style={style.primary}
          className="scrollbar-hidden g-0 block-swipe-nav"
          // onTouchStart={(e) => {
          //   if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return

          //   console.log(`hello`)
          //   e.preventDefault()
          // }}
        >
          {!publicAuthorize ? <ProtectedRoute> </ProtectedRoute> : ""}
          <Header
            {...header}
            firstTitle={title}
            setTitle={setTitle}
            search={search}
            setSearch={setSearch}
          />
          <Component
            setTitle={setTitle}
            search={search}
            setSearch={setSearch}
          />
          <ToastContainer
            className={`custom-toastify`}
            progressClassName={`custom-toastify-progress`}
            icon={false}
            pauseOnFocusLoss={false}
            transition={Flip}
            closeOnClick
            rtl={false}
          >
            <Container></Container>
          </ToastContainer>
        </Container>
      );
    else {
      return (
        // <div className="wrapper">
        //   为使这些转换后的应用软件在品质上达到与专为每个平台开发的应用软件水平，这些软件可自动支持目标平台的独
        //   有功能，
        // </div>
        <Container
          style={style.horizontal}
          className="scrollbar-hidden g-0"
          fluid
          // style={{
          //   backgroundImage: `url(${Rotate})`,
          // }}
        >
          <img
            // height="100%"
            // width="100%"
            src={Rotate}
            alt="rotate"
            style={{ marginTop: "-270px", width: "100vw" }}
          />
          {/* <div className="wrapper">
            为使这些转换后的应用软件在品质上达到与专为每个平台开发的应用软件水平，这些软件可自动支持目标平台的独
            有功能，
          </div> */}
        </Container>
      );
    }
  } else
    return (
      <Container
        style={style.horizontal}
        className="scrollbar-hidden g-0"
        fluid
      >
        <img
          // height="100%"
          // width="100%"
          src={Rotate}
          alt="rotate"
          style={{ marginTop: "-270px", width: "100vw" }}
        />
        {/* <div className="wrapper">
          为使这些转换后的应用软件在品质上达到与专为每个平台开发的应用软件水平，这些软件可自动支持目标平台的独
          有功能，
        </div>{" "} */}
      </Container>
    );
};

export default Screen;
