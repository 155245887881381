import React from "react"
import { Container, Col } from "react-bootstrap"
import NotFoundImage from "./../../../assets/images/notfound.png"
const NotFound = () => {
  return (
    <Container className="skip-padding">
      <Col className="align-vertical-center">
        <img src={NotFoundImage} alt="" />
      </Col>
    </Container>
  )
}

export default NotFound
