import React, { useState, useEffect } from "react"
import { useParams, useLocation, useNavigate } from "react-router"
import { Container, Col, Row, ListGroup, Dropdown } from "react-bootstrap"
import Select from "react-select"
import Navigation from "../common/navbar"
import Text from "../common/text"
import Carousel from "react-multi-carousel"
import _ from "lodash"
import AuctionList from "../common/auction-list"
import Loader from "./../common/loading/loader"
import renderServices from "../../services/render.services"
import common from "./../../utils/common"

const SelectItem = () => {
  const { id, type } = useParams()
  const [navState] = useState({
    navState: `/bidding/room/detail/${id}/${type}`,
  })
  const [loading, setLoading] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [timeLining, setTimeLining] = useState([])
  const [auctionList, setAuctionList] = useState({})

  const retrieveBidRoomData = async (type) => {
    const response = await renderServices.getBiddingRoomDetail({
      code: localStorage.getItem("pin"),
      room_id: localStorage.getItem("room_id"),
      type: type,
    })

    setTimeLining(response.data)
    if (response.data.length > 0) setAuctionList(response.data[0].data)
    setLoading(false)
  }

  useEffect(() => {
    localStorage.setItem("biddingRoom", window.location.pathname)
    setLoading(true)

    if (!id && _.isEmpty(localStorage.getItem("pin"))) {
      navigate("/bidding/room", {
        replace: true,
        state: { navState: "/dashboard" },
      })
    } else {
      retrieveBidRoomData(type)
    }
  }, [])

  const [activeTabId, setActiveTabId] = useState(0)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  }
  const activeTab = async (index) => {
    setActiveTabId(index)
    const response = await renderServices.getBiddingRoomDetail({
      code: localStorage.getItem("pin"),
      room_id: localStorage.getItem("room_id"),
      type: type,
    })
    setTimeLining(response.data)
    setAuctionList(timeLining[index].data)
  }

  const handleClick = (e, data, navState) => {
    e.preventDefault()
    if (_.isEqual(data.hide_at, "false")) {
      navigate(`/deposit/pay/${data.bid_id}`, {
        replace: true,
        state: { data: data, ...navState },
      })
    } else {
      common.sweetAlertCustom({
        title: "通知",
        html: `此拍卖已结束`,
        showCancelButton: false,
        showConfirmButton: false,
      })
    }
  }

  return (
    <React.Fragment>
      <Navigation />
      <div className="main" style={{ position: "unset" }}>
        <div className="main-body grey-bg">
          <Container className="selectItem_upperbody_container">
            <ListGroup horizontal>
              {!loading ? (
                timeLining.map((listing, index) => (
                  <Col
                    key={`time-${index}`}
                    onClick={() => activeTab(index)}
                    className={`selectItem_body_timeline ${
                      index === activeTabId ? `select-active` : ""
                    }`}
                  >
                    <div className="selectItem_body_timeline_word1 x-small-font">
                      {listing.time.slice(0, -3)}
                    </div>
                    <div
                      className="selectItem_body_timeline_word2  x-small-font"
                      style={{}}
                    >
                      {listing.status}
                    </div>
                  </Col>
                ))
              ) : loading ? (
                <Loader />
              ) : (
                ""
              )}
            </ListGroup>
          </Container>
          {/* style={{ marginLeft: "-1em", marginRight: "-1.3em" }} */}
          <div>
            {!loading && auctionList.length > 0 ? (
              auctionList.map((item, index) => (
                <AuctionList
                  key={`auction-list-${index}`}
                  customClickEvent={handleClick}
                  data={item}
                  navState={navState}
                />
              ))
            ) : loading ? (
              <Loader />
            ) : (
              <Row className="select-item-none">
                <Text value="此时间没拍卖" />
              </Row>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SelectItem
