import http from "./http.services"
import auth from "./auth.services"
import config from "../config.json"

async function bidPayment(bid_id) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/bid_payment", {
    bid_id,
  })
  return response.data
}

async function bidTransfer(bid_id) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/bid_transfer", {
    bid_id,
  })
  return response.data
}

export default {
  bidPayment,
  bidTransfer,
}
