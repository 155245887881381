import React, { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// CSS //
import "./App.css";
import "react-multi-carousel/lib/styles.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "@popperjs/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

// Component Page //
import Screen from "./components/screen/Screen";
import Login from "./components/screen/Login";
import Register from "./components/screen/Register";
import Marketplace from "./components/screen/Marketplace";
import BiddingRoom from "./components/screen/BiddingRoom";
import Profile from "./components/screen/Profile";
import Dashboard from "./components/screen/Dashboard";
import Wallet from "./components/screen/Wallet";
import Withdrawal from "./components/screen/Withdrawal";
import FirstAuction from "./components/screen/FirstAuction";
import SecondAuction from "./components/screen/SecondAuction";
import SelectItem from "./components/screen/SelectItem";
import EmptySelectItem from "./components/screen/EmptySelectItem";
import PayDeposit from "./components/screen/PayDeposit";
import ProductDetail from "./components/screen/ProductDetail";
import Meter from "./components/screen/Meter";
import Topup from "./components/screen/Topup";
import TNC from "./components/screen/T&C";
import VIP from "./components/screen/VIP";
import InitialPin from "./components/screen/InitialPin";
import Suggestion from "./components/screen/Suggestion";

// Utils //
import dateUtils from "./utils/date";

// Header //
import template from "./components/common/header-template/header";

import Pin from "./components/common/pin";
import BindAccount from "./components/screen/BindAccount";
import ChangePassword from "./components/screen/ChangePassword";
import ChangePin from "./components/screen/ChangePin";
import BiddingProcess from "./components/screen/BiddingProcess";
import Verification from "./components/screen/Verification";
import Setting from "./components/screen/Setting";
import Marketplacev2 from "./components/screen/Marketplacev2";

// Error //
import NotFound from "./components/common/error/error.notfound";

import ScrollTop from "./components/common/scroll.top";

import SplashScreenLoader from "./components/common/loading/SplashScreen";
import ImageLoader from "./components/common/loading/ImageLoader";

function App() {
  // return <Marketplacev2 />;
  const [loading, setLoading] = useState(false);
  function demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  }
  useEffect((item) => {
    // demoAsyncCall().then(() => setLoading(false));
  });
  if (loading) {
    return <ImageLoader style={{ marginTop: "80%" }} />;
  } else {
    return (
      <React.Fragment>
        <ScrollTop />
        <Routes>
          <Route
            path="/set_pin"
            header={template.getHeader("", "", "")}
            element={<Screen component={InitialPin} />}
          />
          <Route
            path="/"
            exact
            element={
              <Screen
                header={template.getHeader("", "", "")}
                component={Login}
                publicAuthorize={true}
              />
            }
          />
          <Route
            path="/register"
            element={
              <Screen
                header={template.getHeader("", "", "")}
                component={Register}
                publicAuthorize={true}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Screen
                header={template.getHeader(5, "个人主页")}
                component={Profile}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/setting"
            element={
              <Screen
                header={template.getHeader(4, "设置")}
                component={Setting}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <Screen
                header={template.getHeader(3, "竞拍专区", "", "", false)}
                component={Dashboard}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/wallet"
            element={
              <Screen
                header={template.getHeader(3, "钱包")}
                component={Wallet}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/marketplace"
            element={
              <Screen
                header={template.getHeader(
                  2,
                  "商城管理",
                  "寻找商品",
                  false,
                  true
                )}
                component={Marketplacev2}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/bidding/room"
            element={
              <Screen
                header={template.getHeader(7, "高手专区", "寻找专区")}
                component={BiddingRoom}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/withdrawal"
            element={
              <Screen
                header={template.getHeader(4, "银行卡提现")}
                component={Withdrawal}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/auction/first"
            element={
              <Screen
                header={template.getHeader(1, "我的转拍")}
                component={FirstAuction}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/auction/second"
            element={
              <Screen
                header={template.getHeader(1, "我的竞拍")}
                component={SecondAuction}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/bidding/room/detail/:id/:type"
            element={
              <Screen
                header={template.getHeader(6, "高手专区")}
                component={SelectItem}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/bidding/empty_room"
            element={
              <Screen
                header={template.getHeader(8, "")}
                component={EmptySelectItem}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/deposit/pay/:id"
            element={
              <Screen
                header={template.getHeader(3, "平台拍卖")}
                component={PayDeposit}
                publicAuthorize={false}
              />
            }
          />

          <Route
            path="/topup"
            element={
              <Screen
                header={template.getHeader(4, "账户充值")}
                component={Topup}
                publicAuthorize={false}
              />
            }
          ></Route>
          <Route
            path="/product/:id"
            element={
              <Screen
                header={template.getHeader(1, "商品详情")}
                component={ProductDetail}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/meter"
            element={
              <Screen
                header={template.getHeader(3, "我的信用分")}
                component={Meter}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/bind/account"
            element={
              <Screen
                header={template.getHeader(1, "绑定账号")}
                component={BindAccount}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/change/password"
            element={
              <Screen
                header={template.getHeader(1, "更换密码")}
                component={ChangePassword}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/change/pin"
            element={
              <Screen
                header={template.getHeader(1, "重置支付密码")}
                component={ChangePin}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/bidding/process"
            element={
              <Screen
                header={template.getHeader(1, "拍卖中")}
                component={BiddingProcess}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/verification"
            element={
              <Screen
                header={template.getHeader(1, "实名认证")}
                component={Verification}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/suggestion"
            element={
              <Screen
                header={template.getHeader(1, "反馈与建议")}
                component={Suggestion}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/tnc"
            element={
              <Screen
                header={template.getHeader(1, "拍卖规则")}
                component={TNC}
                publicAuthorize={false}
              />
            }
          />
          <Route
            path="/vip"
            element={
              <Screen
                header={template.getHeader(1, "VIP 规则")}
                component={VIP}
                publicAuthorize={false}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Fragment>
    );
  }
}

export default App;
