import http from "./http.services"
import config from "../config.json"
import jwtDecode from "jwt-decode"

const apiEndpoint = config.apiEndPoint + "/login"
const tokenKey = "token"

http.setToken(getToken())

async function login(username, password) {
  const { data } = await http.post(apiEndpoint, {
    phone_number: username,
    password: password,
  })
  const token = data.data.token || ""
  localStorage.setItem(tokenKey, token)

  if (token) return true
  else return false
}

function loginWithToken(data, username) {
  localStorage.setItem(tokenKey, data.token)
  localStorage.setItem("username", username)
  localStorage.setItem("trade_pin", "")
}

function logout() {
  localStorage.clear()
}

function getCurrentUser() {
  const token = localStorage.getItem(tokenKey)
  if (token) return { token }
  else return ""
}

function getToken() {
  return localStorage.getItem(tokenKey)
}

async function pinNumber(data) {
  return await http.post(config.apiEndPoint + "/change_trade_pin", {
    trade_pin: data.trade_pin,
    new_trade_pin: data.new_trade_pin,
    confirm_new_trade_pin: data.confirm_new_trade_pin,
  })
}

async function bankInfo(data) {
  return await http.post(config.apiEndPoint + "/set_bank", {
    account_number: data.account_number,
    holder_name: data.holder_name,
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  loginWithToken,
  logout,
  getCurrentUser,
  getToken,
  pinNumber,
  bankInfo,
}
