import React, { useState, useEffect } from "react"
import { useParams, useLocation, useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { Container, Col, Row, ListGroup } from "react-bootstrap"
import Navigation from "../common/navbar"
import Text from "../common/text"
import AuctionList from "../common/auction-list"
import Loader from "./../common/loading/loader"
import renderServices from "../../services/render.services"

const EmptySelectItem = ({ setTitle }) => {
  const [loading, setLoading] = useState(false)
  const { id, type } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const { state } = useLocation()
  const navigate = useNavigate()
  const [timeLining, setTimeLining] = useState([])
  const [auctionList, setAuctionList] = useState({})

  const retrieveBidRoomData = async (type) => {
    // const response = await renderServices.getBiddingRoomDetail({
    //   code: localStorage.getItem("pin"),
    //   room_id: localStorage.getItem("room_id"),
    //   type: type,
    // });
    const emptyTimeLining = [
      { time: "00:00:00", status: "即将开始" },
      { time: "01:00:00", status: "即将开始" },
      { time: "02:00:00", status: "即将开始" },
      { time: "03:00:00", status: "即将开始" },
      { time: "04:00:00", status: "即将开始" },
      { time: "05:00:00", status: "即将开始" },
      { time: "06:00:00", status: "即将开始" },
      { time: "07:00:00", status: "即将开始" },
      { time: "08:00:00", status: "即将开始" },
      { time: "09:00:00", status: "即将开始" },
      { time: "10:00:00", status: "即将开始" },
      { time: "11:00:00", status: "即将开始" },
      { time: "12:00:00", status: "即将开始" },
      { time: "13:00:00", status: "即将开始" },
      { time: "14:00:00", status: "即将开始" },
      { time: "15:00:00", status: "即将开始" },
      { time: "16:00:00", status: "即将开始" },
      { time: "17:00:00", status: "即将开始" },
      { time: "18:00:00", status: "即将开始" },
      { time: "19:00:00", status: "即将开始" },
      { time: "20:00:00", status: "即将开始" },
      { time: "21:00:00", status: "即将开始" },
      { time: "22:00:00", status: "即将开始" },
      { time: "23:00:00", status: "即将开始" },
    ]

    setTimeLining(emptyTimeLining)
    // if (response.data.length > 0) setAuctionList(response.data[0].data);
    setLoading(false)
  }

  useEffect(() => {
    setTitle(searchParams.get("name"))
    setLoading(true)
    retrieveBidRoomData(type)

    // if (!id && _.isEmpty(localStorage.getItem("pin"))) {
    // //   navigate("/bidding/room", { replace: true });
    // } else {
    //   retrieveBidRoomData(type);
    // }
  }, [])

  const [activeTabId, setActiveTabId] = useState(0)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  }
  const activeTab = async (index) => {
    setActiveTabId(index)
    const response = await renderServices.getBiddingRoomDetail({
      code: localStorage.getItem("pin"),
      room_id: localStorage.getItem("room_id"),
      type: type,
    })
    setTimeLining(response.data)
    setAuctionList(timeLining[index].data)
  }

  const handleClick = (e, data) => {
    e.preventDefault()
    navigate(`/deposit/pay/${data.bid_id}`, {
      state: { data: data },
    })
  }

  return (
    <React.Fragment>
      <Navigation />
      <div className="main" style={{ position: "unset" }}>
        <div className="main-body grey-bg">
          <Container className="selectItem_upperbody_container">
            <ListGroup horizontal>
              {!loading ? (
                timeLining.map((listing, index) => (
                  <Col
                    key={`time-${index}`}
                    onClick={() => activeTab(index)}
                    className={`selectItem_body_timeline ${
                      index === activeTabId ? `select-active` : ""
                    }`}
                  >
                    <div className="selectItem_body_timeline_word1 x-small-font">
                      {listing.time}
                    </div>
                    <div className="selectItem_body_timeline_word2  x-small-font">
                      {listing.status}
                    </div>
                  </Col>
                ))
              ) : loading ? (
                <Loader />
              ) : (
                ""
              )}
            </ListGroup>
          </Container>
          <div>
            {!loading && auctionList.length > 0 ? (
              auctionList.map((item, index) => (
                <AuctionList
                  key={`auction-list-${index}`}
                  customClickEvent={handleClick}
                  data={item}
                />
              ))
            ) : loading ? (
              <Loader />
            ) : (
              <Row className="select-item-none">
                <Text value="此时间没拍卖" />
              </Row>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmptySelectItem
