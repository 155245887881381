import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Text from "./text";

const BottomBar = ({
  bid_id,
  price,
  customClickEvent,
  setClickCount,
  buttonDisable,
  setButtonDisable,
  buttonName,
}) => {
  const [customTimeout, setCustomTimeout] = useState();
  const countdownReset = () => {
    if (customTimeout) {
      clearTimeout(customTimeout);
    }

    setCustomTimeout(
      setInterval(() => {
        setButtonDisable(false);
      }, 1500)
    );
  };

  return (
    <Row
      onClick={(e) => {
        setClickCount((clickCount) => clickCount + 1);
        customClickEvent(e, bid_id, setClickCount);
        if (!buttonDisable) {
          setButtonDisable(true);
          countdownReset();
        }
      }}
      className="custom-fixed-bottom g-0"
    >
      <Col xs={6} className="bottom-bar-left">
        <Text value={price} />
      </Col>
      <Col
        xs={6}
        className="skip-padding skip-margin bottom-bar-right white-font "
      >
        <Button disabled={buttonDisable}>
          <Text value={buttonName} />
        </Button>
      </Col>
    </Row>
  );
};

export default BottomBar;
