import React, { useState } from "react";
import Pin from "./../common/pin";

const InitialPin = () => {
  const [data, setData] = useState({
    pin: true,
    name: `设定交易密码`,
    closeFlag: true,
    api: "/set_trade_pin",
    link: "/profile",
    method: "post",
    type: "set_trade_pin",
  });
  const closePin = () => {
    setData({
      pin: false,
    });
  };
  return <Pin data={data} closePin={closePin} />;
};

export default InitialPin;
