import React, { useEffect, useState, useRef, createRef } from "react";
import {
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Card,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import _ from "lodash";

const Header = ({
  header,
  title,
  titleAlign,
  backButton,
  background,
  fontColor,
  searchBar,
  searchTitle,
  iconColor,
  rightButton,
  rightIcon,
  rightLink,
  dropDown,
  firstTitle,
  setTitle,
  setSearch,
  selfRefresh,
  search,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [headerClass, setHeaderClass] = useState("");
  const [isComposition, setIsComposition] = useState(false);
  const titleRef = useRef();
  // const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  let searchPlaceholder = searchParams.get("search");
  if (searchPlaceholder === null || searchPlaceholder === "") {
    searchPlaceholder = searchTitle;
  }
  const handleScroll = (event) => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 10) setHeaderClass("header-appear");
    else setHeaderClass("header-disappear");
  };
  const handleSearchChange = (e) => {
    if (!isComposition) {
      setSearch(e.target.value);
    }
  };
  const handleComposition = (ev) => {
    if (ev.type === "compositionend") {
      setIsComposition(false);
      if (isComposition) {
        setSearch(ev.target.value);
      }
    } else {
      setIsComposition(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, []);
  useEffect(() => {
    if (
      !_.includes(["/bidding/room", "/marketplace"], window.location.pathname)
    ) {
      setSearch("");
    }
  });
  if (header)
    return (
      <div className={`main-header ${background} ${headerClass} `}>
        <Container>
          <Row className={`header-bar ${background} g-0`}>
            {title === "高手专区" &&
            window.location.pathname !== "/bidding/room" ? (
              <React.Fragment>
                <Col xs={titleAlign === "center" ? "" : 7}>
                  <h2
                    id="header-title"
                    ref={titleRef}
                    className={`${fontColor} large-font`}
                    style={{ textAlign: "right", paddingTop: 10 }}
                    onClick={() => {
                      if (selfRefresh) {
                        window.location.reload(false);
                      }
                    }}
                  >
                    {firstTitle ? firstTitle : title}
                  </h2>
                  {backButton ? (
                    <Col
                      className="item-float"
                      style={{
                        float: "left",
                        left: "1.5em",
                        top: "1.8em",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => {
                        setTitle("");
                        if (
                          _.includes(
                            ["/auction/first", "/auction/second", "/wallet"],
                            window.location.pathname
                          )
                        ) {
                          navigate("/profile", { replace: true });
                        } else if (
                          window.location.pathname.includes(
                            "/bidding/room/detail/"
                          )
                        ) {
                          navigate("/bidding/room", { replace: true });
                        } else if (
                          window.location.pathname.includes("/deposit/pay/")
                        ) {
                          navigate(localStorage.getItem("biddingRoom"));
                        } else if (
                          window.location.pathname.includes("/bidding/process")
                        ) {
                          navigate(
                            localStorage.getItem("depositPay", {
                              state: { id: localStorage.getItem("depositId") },
                            })
                          );
                        } else if (
                          window.location.pathname.includes("/setting")
                        ) {
                          navigate("/profile", {
                            replace: true,
                          });
                        } else if (
                          window.location.pathname.includes("/bidding/room")
                        ) {
                          navigate("/dashboard", {
                            replace: true,
                          });
                        } else if (window.location.pathname.includes("/vip")) {
                        } else {
                          if (state) {
                            navigate(state.navState, { replace: true });
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        style={{ color: iconColor }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {rightButton ? (
                    <Col
                      className="item-float"
                      style={{ float: "right", right: "2em", top: "1.3em" }}
                      onClick={() => {
                        navigate(rightLink, {
                          state: { navState: "/profile" },
                          replace: true,
                        });
                      }}
                    >
                      {rightIcon ? (
                        <Card.Img
                          className=""
                          src={rightIcon}
                          style={{ width: "1.8rem", height: "1.8rem" }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col xs={titleAlign === "center" ? "" : 5}>
                  <h2
                    id="header-title"
                    ref={titleRef}
                    className={`${fontColor} large-font`}
                    style={{ textAlign: "center", paddingTop: 10 }}
                    onClick={() => {
                      if (selfRefresh) {
                        window.location.reload(false);
                      }
                    }}
                  >
                    {firstTitle ? firstTitle : title}
                  </h2>
                  {backButton ? (
                    <Col
                      className="item-float"
                      style={{
                        float: "left",
                        left: "1.5em",
                        top: "1.8em",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => {
                        setTitle("");
                        if (
                          _.includes(
                            ["/auction/first", "/auction/second", "/wallet"],
                            window.location.pathname
                          )
                        ) {
                          navigate("/profile", { replace: true });
                        } else if (
                          window.location.pathname.includes(
                            "/bidding/room/detail/"
                          )
                        ) {
                          navigate("/bidding/room", { replace: true });
                        } else if (
                          window.location.pathname.includes("/deposit/pay/")
                        ) {
                          navigate(localStorage.getItem("biddingRoom"));
                        } else if (
                          window.location.pathname.includes("/bidding/process")
                        ) {
                          const depoObj = JSON.parse(
                            localStorage.getItem("depositState")
                          );
                          if (depoObj) {
                            navigate(localStorage.getItem("depositPay"), {
                              replace: true,
                              state: depoObj,
                            });
                          } else {
                            const bid_history_type =
                              localStorage.getItem("bid_history_type");
                            if (bid_history_type) {
                              navigate(bid_history_type, { replace: true });
                            } else {
                              navigate("/profile", { replace: true });
                            }
                          }
                        } else if (
                          window.location.pathname.includes("/setting")
                        ) {
                          navigate("/profile", {
                            replace: true,
                          });
                        } else if (
                          window.location.pathname.includes("/bidding/room")
                        ) {
                          navigate("/dashboard", {
                            replace: true,
                          });
                        } else if (window.location.pathname.includes("/vip")) {
                          // only for deposit page -> vip
                          const depoObj = JSON.parse(
                            localStorage.getItem("depositState")
                          );
                          if (depoObj) {
                            navigate(localStorage.getItem("depositPay"), {
                              replace: true,
                              state: depoObj,
                            });
                          } else {
                            if (state) {
                              navigate(state.navState, { replace: true });
                            }
                          }
                        } else if (window.location.pathname.includes("/tnc")) {
                          // only for deposit page -> tnc
                          const depoObj = JSON.parse(
                            localStorage.getItem("depositState")
                          );
                          if (depoObj) {
                            navigate(localStorage.getItem("depositPay"), {
                              replace: true,
                              state: depoObj,
                            });
                          } else {
                            if (state) {
                              navigate(state.navState, { replace: true });
                            }
                          }
                        } else if (
                          window.location.pathname.includes("/withdrawal")
                        ) {
                          navigate("/wallet", { replace: true });
                        } else {
                          if (state) {
                            navigate(state.navState, { replace: true });
                          } else {
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        style={{ color: iconColor }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {rightButton ? (
                    <Col
                      className="item-float"
                      style={{ float: "right", right: "2em", top: "1.3em" }}
                      onClick={() =>
                        navigate(rightLink, {
                          state: { navState: "/profile", replace: true },
                        })
                      }
                    >
                      {rightIcon ? (
                        <Card.Img
                          className=""
                          src={rightIcon}
                          style={{ width: "1.8rem", height: "1.8rem" }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>
              </React.Fragment>
            )}
            {searchBar ? (
              <Col>
                <InputGroup className="mb-3" style={{ width: "auto" }}>
                  <InputGroup.Text
                    className="border-left-round"
                    id="basic-addon1"
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} inverse />
                  </InputGroup.Text>
                  <FormControl
                    className="border-right-round white-input-placeholder"
                    placeholder={searchPlaceholder}
                    aria-label="product"
                    aria-describedby="basic-addon1"
                    onChange={handleSearchChange}
                    onCompositionStart={handleComposition}
                    onCompositionUpdate={handleComposition}
                    onCompositionEnd={handleComposition}
                    // search={search}
                    // onBlur={(item) => {
                    //   console.log("asd", item);
                    //   setSearchInput(item);
                    // }}
                  />
                </InputGroup>
              </Col>
            ) : (
              ""
            )}

            {dropDown ? (
              <Col>
                <Dropdown
                  className="custom-drop-down"
                  // style={{ backgroundColor: "white", color: "#555353" }}
                >
                  <Dropdown.Toggle
                    data-bs-toggle="dropdown"
                    id="dropdown-basic"
                    // style={{ backgroundColor: "white" }}
                  >
                    选择
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="white-font"
                      href={`/bidding/room/detail/${id}/bid`}
                      style={{ backgroundColor: "white", color: "#555353" }}
                    >
                      平台拍卖
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="white-font"
                      href={`/bidding/room/detail/${id}/transfer`}
                      style={{ backgroundColor: "white", color: "#555353" }}
                    >
                      转拍拍卖
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    );
  else {
    return "";
  }
};

export default Header;
