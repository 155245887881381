import http from "./http.services"
import auth from "./auth.services"
import config from "../config.json"

async function listCategories() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/category")
  return response.data
}

async function listSubCategories(category_id) {
  http.setToken(auth.getToken())
  const response = await http.get(
    config.apiEndPoint + "/category/" + category_id
  )
  return response.data
}

const listCategoriesWithPromise = () => {
  http.setToken(auth.getToken())
  http.get(config.apiEndPoint + "/category").then((response) => {
    return response.data
  })
}

async function listProduct(subcategory_id, search, start = 0, limit = 10) {
  http.setToken(auth.getToken())
  let param = `?start=${start}&length=${limit}`
  if (subcategory_id > 0 && search !== null) {
    param = `?sub_category_id=${subcategory_id}&start=${start}&length=${limit}&search=${search}`
  } else {
    if (subcategory_id > 0) {
      param = `?sub_category_id=${subcategory_id}&start=${start}&length=${limit}`
    }
    if (search !== null) {
      param = "?search=" + search + `&start=${start}&length=${limit}`
    }
  }
  const response = await http.get(config.apiEndPoint + `/product` + param)
  return response.data //response.data;
}

async function listBank() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/bank")
  return response
}

async function listRoom() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/rooms")
  return response
}

async function getProduct(id) {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/product/" + id)
  return response.data || {}
}

async function userInfo() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/profile")
  return response.data
}

async function userBalance() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/getWalletBalance")
  return response.data
}

async function bidHistoryList(type, bid_status) {
  http.setToken(auth.getToken())
  const response = await http.get(
    config.apiEndPoint + "/bid?type=" + type + "&status=" + bid_status
  )
  return response.data
}

async function walletHistory() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/wallet")
  return response.data
}

async function biddingRoom(search) {
  // console.log("calling api ", search);
  http.setToken(auth.getToken())
  let param = ""
  if (search != null && search != "") {
    param = `?search=${search}`
  }

  const response = await http.get(config.apiEndPoint + `/rooms` + param)
  return response.data
}

async function getProductDetail(product_id) {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/product/" + product_id)
  return response.data
}

async function getBiddingProcess(bid_id) {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/bid/" + bid_id)
  return response.data
}

async function getBiddingRoomDetail(data) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/room_with_bid_item", {
    code: data.code,
    room_id: data.room_id,
    type: data.type,
  })
  return response.data || {}
}
async function getBidInfo(data) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/bid/bid_info", {
    id: data.id,
    code: data.code,
  })
  return response.data || {}
}

async function getAnnouncement() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/announcement")
  return response.data
}

async function getAppVersion() {
  const response = await http.get(config.apiEndPoint + "/app_version")
  return response.data
}

export default {
  listCategories,
  listCategoriesWithPromise,
  listBank,
  listRoom,
  listProduct,
  getProduct,
  userInfo,
  userBalance,
  bidHistoryList,
  walletHistory,
  biddingRoom,
  getProductDetail,
  getBiddingProcess,
  getBiddingRoomDetail,
  getBidInfo,
  getAnnouncement,
  listSubCategories,
  getAppVersion,
}
