import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Text from "./text";
import Span from "./span";

const Merchant = ({ merchant, slug }) => {
  useEffect(() => {}, []);

  return (
    <Row className="merchant-detail g-0">
      <Row style={{ padding: 10 }} className="g-0">
        <Col xs={3}>
          <img
            className="product-detail-image-radius"
            src={merchant.mainMediaUrl || ""}
            alt=""
          />
        </Col>
        <Col xs={9}>
          <Row>
            <Text
              className="text-left small-font"
              value={merchant.name ? merchant.name : ""}
            ></Text>
          </Row>
          <Row>
            <Span
              className="product-detail-span small-font"
              style={{ width: "50%" }}
              value="官方认可代理商"
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Text className="text-left small-font" value="商品详情 :" />
      </Row>
      <Row>
        <div
          className="text-left small-font grey-font"
          style={{ color: "darkslategrey", paddingBottom: "5em" }}
          dangerouslySetInnerHTML={{ __html: slug || "" }}
        />
      </Row>
    </Row>
  );
};

export default Merchant;
