import React from "react"
import { Nav, NavItem } from "reactstrap"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import IconFirst from "./../../assets/images/icon-1-new.png"
import IconSecond from "./../../assets/images/icon-2-new.png"
import IconThird from "./../../assets/images/icon-3-new.png"

import {
  faSearch,
  faHome,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons"

const tabs = [
  {
    route: "/dashboard",
    icon: IconFirst,
    label: "Home",
  },
  {
    route: "/marketplace",
    icon: IconSecond,
    label: "Marketplace",
  },
  {
    route: "/profile",
    icon: IconThird,
    label: "Profile",
  },
]

const Navigation = () => {
  return (
    <div>
      {/* Bottom Tab Navigator*/}
      <nav
        className="navbar fixed-bottom navbar-light nav-custom"
        role="navigation"
      >
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  replace
                  className="nav-link skip-padding skip-color"
                  //   activeClassName="active"
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    {/* <FontAwesomeIcon
                      size="lg"
                      className="custom-icon-color"
                      icon={tab.icon}
                    /> */}
                    <img
                      src={tab.icon}
                      alt=""
                      style={{
                        height: 40,
                        width: 65,
                        filter: "drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25))",
                      }}
                    />
                    {/* <div>{tab.label}</div> */}
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  )
}

export default Navigation
