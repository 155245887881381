import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import CategoryCard from "./category.card";
import Loader from "./loading/loader";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { auto } from "@popperjs/core";

const CarouselCustom = ({
  data,
  customClickEvent,
  loadingCategory,
  backButton,
  backButtonColor,
  backButtonLink = null,
  setShowCategory, // not reusable
  getProducts, // not reusable
  search, // not reusable
}) => {
  const navigate = useNavigate();

  const [responsive] = useState({
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  });
  return (
    <>
      <Carousel
        responsive={responsive}
        showDots={false}
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000000}
        centerMode={false}
        containerClass="container-padding-bottom"
      >
        {!loadingCategory && data ? (
          data.map((category) => (
            <CategoryCard
              key={`category-card-${category.id}`}
              data={category}
              customClickEvent={customClickEvent}
              dataInterval={false}
            />
          ))
        ) : loadingCategory ? (
          <Loader style={{ justifyContent: "center" }} />
        ) : (
          ""
        )}
      </Carousel>
      {backButton && (
        <Card
          style={{
            width: "95%",
            // paddingTop: "0.6em",
            fontSize: "0.8em",
            margin: "1em auto 0 auto",
          }}
          onClick={(e) => {
            // console.log("back btn");
            if (backButtonLink) {
              setShowCategory(true);
              getProducts(null, search);
            } else {
              navigate(-1);
            }
          }}
          key={`back-button}`}
          className="custom-card"
        >
          {/* <FontAwesomeIcon
            icon={faChevronLeft}
            style={{
              color: backButtonColor,
              // marginTop: "50%",
              // marginBottom: "30%",
            }}
          /> */}
          <Card.Text
            style={{ marginTop: "auto" }}
            className="text-center tiny-font"
          >
            返回
          </Card.Text>
        </Card>
      )}
    </>
  );
};

export default CarouselCustom;
