import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Iframe from "react-iframe";
import _ from "lodash";

// Custom //
import FormikForm from "../common/formik.form";
// Assets //
import logo from "../../assets/images/logo.png";
// Services //
import userServices from "../../services/user.services";
import authServices from "../../services/auth.services";
import common from "../../utils/common";

import { Logo2 } from "../../assets/images/index";
import { LiveChatWidget, useWidgetState } from "@livechat/widget-react";
// import IconButton from "@material-ui/core/IconButton"
// import InputLabel from "@material-ui/core/InputLabel"
// import Visibility from "@material-ui/icons/Visibility"
// import InputAdornment from "@material-ui/core/InputAdornment"
// import VisibilityOff from "@material-ui/icons/VisibilityOff"
// import Input from "@material-ui/core/Input"
import {
  parsePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
async function onSubmit(
  data,
  setFieldError,
  navigate,
  setSubmitting,
  resetForm,
  defaultData,
  newPassword
) {
  try {
    const { data: response } = await userServices.register({ ...data });
    const token = response.data.token || "";

    if (token) {
      // authServices.loginWithToken(response.data, data.phone_number)
      common.sweetAlertCustom({
        title: "通知",
        html: `恭喜你,你已注册成功！`,
        showCancelButton: false,
        showConfirmButton: false,
      });
      resetForm({ defaultData });

      navigate("/");
    }
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors;
      // toast.dismiss()
      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item]);
        });
      }
    }
  }
  setSubmitting(false);
}

const Register = () => {
  const navigate = useNavigate();
  // const [hide, setHide] = useState("hide")
  const [data, setData] = useState({
    phone_number: "",
    password: "",
    invitation_code: "",
    showPassword: false,
  });
  const [testPw, setTestPw] = useState("");
  const [visibility, setVisibility] = useState("minimized");
  const widgetState = useWidgetState();
  const registerSchema = Yup.object({
    // phone_number: Yup.string()
    //   .typeError("手机号码格式必须是数字")
    //   .required("需要填入手机号码")
    //   .min(12, "手机号格式错误"),
    phone_number: Yup.string()
      .typeError("手机号码格式必须是数字")
      .required("需要填入手机号码")
      .test("phone_number", "填入您的手机号码", (value) => {
        if (value != "" && value != undefined) {
          const phoneNumber = parsePhoneNumber(value);
          const AUphoneNum = parsePhoneNumber(formatPhoneNumberIntl(value));

          if (AUphoneNum !== "" && AUphoneNum !== undefined) {
            if (AUphoneNum.countryCallingCode == "61") {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
          }

          if (phoneNumber) {
            if (phoneNumber.country === "US" || phoneNumber.country === "CA") {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "HK" || phoneNumber.country === "MO") {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "SG") {
              if (value.length >= 11) {
                return true;
              } else {
                return false;
              }
            }
            if (
              phoneNumber.country === "MY" ||
              phoneNumber.country === "AU" ||
              phoneNumber.country === "TH"
            ) {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "TW") {
              if (value.length >= 13) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "CN") {
              if (value.length >= 14) {
                return true;
              } else {
                return false;
              }
            }
          }
        }
      }),
    password: Yup.string().required("需要填入密码"),
    invitation_code: Yup.string().required("需要填入邀請码"),
  });
  const handleClickShowPassword = () => {
    setData({ ...data, showPassword: !data.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }
  return (
    <React.Fragment>
      <Container className="container-full-white">
        <Row className="text-right large-font g-0">
          <NavLink className="black-font" to="/">
            登入
          </NavLink>
        </Row>
        <Row className="logo g-0">
          <img src={Logo2} alt="logo" className="logo-img" />
        </Row>
        <Row className="logo-label g-0">
          <label>欢迎使用京东拍卖商城</label>
        </Row>
        <Row className="login-form g-0">
          <Formik
            initialValues={data}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
              // console.log("onsubmit", values)
              onSubmit(
                values,
                setFieldError,
                navigate,
                setSubmitting,
                resetForm,
                data,
                values.password
              );
            }}
          >
            {({ setFieldValue, errors, isSubmitting, isValid, values }) => (
              <Form>
                {FormikForm.renderMobileInput(
                  "phone_number",
                  "请输入您的手机号码",
                  setFieldValue,
                  errors
                )}
                {FormikForm.renderInput(
                  "password",
                  "请输入密码",
                  "text",
                  "new-password",
                  "key",
                  true
                )}
                {/* <Row className="custom-field">
                  <Input
                    type={data.showPassword ? "text" : "text"}
                    style={{
                      height: "40px",
                      border: "0px !important",
                      background: "#f9f9f9",
                      borderRadius: "2em",
                      color: "#0c0000",
                    }}
                    onChange={(e) => {
                      var x = "";
                      if (e.target.value.indexOf("*") > -1) {
                        x = testPw.concat(replaceAll(e.target.value, "*", ""));
                      } else {
                        x = e.target.value;
                      }
                      setFieldValue("password", e.target.value);
                      setTestPw(x);
                    }}
                    onKeyDown={(event) => {
                      var key = event.keyCode || event.charCode;

                      if (key == 8 || key == 46) {
                        setTestPw("");
                        setFieldValue("password", "");
                      }
                    }}
                    className="key"
                    value={
                      !data.showPassword
                        ? "*".repeat(values.password.length)
                        : testPw
                    }
                    autoComplete={"new-password"}
                    placeholder="请输入密码"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {data.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <ErrorMessage
                    className="text-danger text-right no-padding error-message"
                    name={"password"}
                    component="div"
                  />
                </Row> */}
                {FormikForm.renderInput(
                  "invitation_code",
                  "请输入邀请码",
                  "referral",
                  "off",
                  "invitation_code"
                )}
                {/* {FormikForm.renderButton("立即注册", isSubmitting)} */}
                <Row className="custom-button">
                  <button
                    type="submit"
                    className="btn"
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? "立即注册" : "立即注册"}
                  </button>
                </Row>
                <Row>
                  <Col className="text-center" style={{ color: "#878585" }}>
                    遇到问题？您可以
                    <span
                      onClick={() => {
                        // setHide("")

                        window.open(" https://direct.lc.chat/14022327/");
                        // setVisibility("maximized");
                      }}
                      className="mediumgrey-font"
                      style={{ color: "rgb(104 150 217)" }}
                    >
                      联系客服
                    </span>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Row>
        {/* <LiveChatWidget
          license="14022327"
          visibility={visibility}
          // visibility="minimized"
          // onNewEvent={() => {
          //   console.log("new");
          //   setVisibility("hidden");
          // }}
          onVisibilityChanged={() => {
            // if (startCount != 0) {
            //   console.log("it changed");
            //   setVisibility("minimized");
            // }
            if (widgetState.visibility === "maximized") {
              setVisibility("minimized");
            }
          }}
        /> */}
        {/* {_.isEqual(hide, "") ? (
          <div>
            <Iframe
              url="https://direct.lc.chat/14022327/"
              width="100%"
              height="100%"
              id="myId"
              className={`iframe-custom ${hide}`}
              position="fixed"
              overflow="hidden"
            ></Iframe>
            <Button
              className={`iframe-close-button ${hide}`}
              onClick={() => {
                setHide("hide")
              }}
            >
              <FontAwesomeIcon value="10" icon={faX} />
            </Button>
          </div>
        ) : (
          ""
        )} */}
      </Container>
    </React.Fragment>
  );
};

export default Register;
