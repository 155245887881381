import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ChatImage from "./../../assets/images/chat-new.png"
import Text from "./text"
import { AngPao } from "../../assets/images"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { 
  faUserShield, faUserCircle
} from "@fortawesome/free-solid-svg-icons"

const ChatMessage = ({ data }) => {
  if (data.username !== localStorage.getItem("username")) {
    return (
      <Container className="chat-message-container">
        <Row className="chat-message-row g-0">
          <Col xs={2} style={{ backgroundColor: "#f4f4f4",justifyContent:"center" }} > 
            <FontAwesomeIcon className="chat-message-icon" icon={faUserCircle}  style={{
              color: "grey"
            }}/> 
          </Col>
          <Col xs={10} style={{ backgroundColor: "#f4f4f4" }}>
            <Row className="chat-message-username">
              <Text
                value={`${data.display_name} ${data.bid_time}`}
                className="x-super-tiny-font"
              />
            </Row>
            <Row className="chat-message-product">
              <Col xs={3} className="chat-message-image-col">
                {/* <Row className="chat-message-image"> */}
                <img className="chat-bid-icon" src={AngPao} alt="" />
                {/* </Row> */}
              </Col>
              <Col xs={9}>
                <Row>
                  <Col xs={5} className="text-right skip-padding">
                    <Text value="出价 ：" className="small-font" />
                  </Col>
                  <Col xs={7} className="skip-padding">
                    <Text
                      value={`￥${data.bid_price}`}
                      className="small-font red-font text-left"
                      style={{ padding: "0em 0.5em" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} className="text-right skip-padding">
                    <Text value="奖励 ：" className="small-font" />
                  </Col>
                  <Col xs={7} className="skip-padding">
                    <Text
                      value={`￥${data.bonus}`}
                      className="small-font red-font text-left"
                      style={{ padding: "0em 0.5em" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <Container className="chat-message-container">
        <Row className="chat-message-row">
          <Col xs={10} style={{ backgroundColor: "#f4f4f4", paddingRight: "0" }}>
            <Row className="chat-message-username text-right skip-padding">
              <Text
                value={`${data.display_name} ${data.bid_time}`}
                className="x-super-tiny-font"
              />
            </Row>
            <Row className="chat-message-product align-item-right">
              <Col xs={9}>
                <Row>
                  <Col xs={5} className="text-right skip-padding">
                    <Text value="出价 ：" className="small-font" />
                  </Col>
                  <Col xs={7} className="skip-padding">
                    <Text
                      value={`￥${data.bid_price}`}
                      className="small-font red-font text-left"
                      style={{ padding: "0em 0.5em" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} className="text-right skip-padding">
                    <Text value="奖励 ：" className="small-font" />
                  </Col>
                  <Col xs={7} className="skip-padding">
                    <Text
                      value={`￥${data.bonus}`}
                      className="small-font red-font text-left"
                      style={{ padding: "0em 0.5em" }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} className="chat-message-image-col">
                {/* <Row className="chat-message-image"> */}
                <img className="chat-bid-icon" src={AngPao} alt="" />
                {/* </Row> */}
              </Col>
            </Row>
          </Col>
          <Col xs={2} style={{justifyContent:"center"}}>
            <FontAwesomeIcon className="chat-message-icon" icon={faUserCircle} style={{
              color: "grey"
            }} />  
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ChatMessage
