import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function init() {
  Sentry.init({
    dsn: "https://a5dba908d8564c459697b6dd6f3f0841@o1154711.ingest.sentry.io/6234585",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function log(error) {
  Sentry.captureException(error);
}

const logService = {
  init: init,
  log: log,
};

export default logService;
