import React, { useRef, useEffect, useState } from "react"
import authServices from "../services/auth.services"
import _ from "lodash"
import { toast } from "react-toastify"
import uuid from "react-uuid"
import { Container } from "react-bootstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

async function authNavigate(navigate) {
  const token = await authServices.getCurrentUser()
  if (token && !_.isEmpty(token)) navigate("/dashboard", { replace: true })
}

export function loading() {
  return (
    <div className="loader-main">
      <div className="loader"></div>
    </div>
  )
}

export const sweetAlertCustom = (element) => {
  const MySwal = withReactContent(Swal)

  return MySwal.fire({
    ...element,
    customClass: {
      title: "custom-sweet-alert-title",
    },
  }).then((result) => {})
}

export const toastEvent = (element) => {
  toast.dismiss()
  toast(<Container>{element}</Container>)
}
export const toastHTML = (component) => {
  toast.dismiss()
  toast(<div>{component}</div>, {
    toastId: uuid(),
  })
}

export default {
  authNavigate,
  loading,
  toastEvent,
  toastHTML,
  sweetAlertCustom,
}
