import React from "react";
import { Container, Row } from "react-bootstrap";
import TnCHeader from "./../../assets/images/tnc-header.png";
import Text from "./../common/text";

const TNC = () => {
  return (
    <Container className="container-full-white">
      {/* <Row className="tnc-row">
        <img src={TnCHeader} alt="" />
      </Row> */}
      <Row className="tnc-row">
        <Text value="1.拍卖采用固定阶梯加价递增模式,所有商品均从起拍价起拍,按照固定加价幅度阶梯加价。" />
      </Row>
      <Row className="tnc-row">
        <Text value="2.拍卖开始后,用户按照固定加价幅度出价,用户出价领先时该用户不可继续出价。" />{" "}
      </Row>
      <Row className="tnc-row">
        <Text value="3. 平台所有拍卖会提前预展,用户需提前缴纳保证金方即可报名参加拍卖。" />
      </Row>
      <Row className="tnc-row">
        <Text value="4.如果在规定的拍卖时间内最高出价未超过保留价,本次拍卖自动流拍,所有用户保证金退回,红包无效。" />
      </Row>
      <Row className="tnc-row">
        <Text value="5.如果最高出价超过保留价并且拍卖时间或最高出价大于最高限价,本次拍卖结束,以最高出价者获得拍品。" />
      </Row>
      <Row className="tnc-row">
        <Text value="6.竞拍结束后,获得拍品的用户需缴纳尾款,如果在48小时内未缴纳尾款,冻结的保证金将作为违约金扣除。其他竞拍失败者保证金会在拍卖结束后5分钟内退还。" />
      </Row>
      <Row className="tnc-row">
        <Text value="7.用户每次出价都会获得加价幅度百分之十奖励,拍卖红包会在拍卖结束后结算到用户余额账户中。" />
      </Row>
      <Row className="tnc-row">
        <Text
          value="8. 平台坚持公开,
        公平,公正的原则,禁止用户使用一切非本人点击出价的一切手段和手法,禁止使用外挂借口或一切破坏公平出价的手段和方法,若被发现,平台将采取包括但不限于查封账户,停止提现,
        追回红包等保护措施。"
        />
      </Row>
      <Row className="tnc-row">
        <Text
          value="9. 用户在平台参与竞拍,表示用户已充分了解平台的
          拍卖规则和竞拍商品的洋细信息。用户出价成功表示
          用户在所有价格下接受拍品的一切现状。"
        />
      </Row>
    </Container>
  );
};

export default TNC;
