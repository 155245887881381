import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { NavLink } from "react-router-dom"

import renderServices from "../../services/render.services"
import Text from "./../common/text"
import Loader from "./../common/loading/loader"

const Wallet = () => {
  const [navState] = useState({ navState: "/wallet" })
  const [loading, setLoading] = useState(false)
  const [balance, setBalance] = useState("-")
  const [pendingWithdrawalAmount, setPendingWithdrawalAmount] = useState("-")
  const [walletList, setWalletList] = useState({})
  const [bindAccount, setBindAccount] = useState(false)

  const getBalance = async () => {
    const response = await renderServices.userBalance()
    setBalance(response.data.wallet_balance)
    setPendingWithdrawalAmount(response.data.pending_withdrawal_amount)
  }

  const getWalletList = async () => {
    const response = await renderServices.walletHistory()
    setWalletList(response.data)
    setLoading(false)
  }
  function showListing() {
    return (
      <div style={{ backgroundColor: "#f4f4f4", height: "91vh" }}>
        {!loading && walletList.length > 0 ? (
          walletList.map((walletList, index) => (
            <div
              key={`wallet-history-${index}`}
              className="wallet_frame_rowList"
            >
              <div className="wallet_frame_rowPadding">
                <Col>
                  <div className="wallet_frame_nameList">
                    {walletList.description}
                  </div>
                </Col>
                <Col className="wallet_frame_colBonus">
                  <div className="wallet_frame_bonusList">
                    ￥ {walletList.amount}
                  </div>
                </Col>
              </div>
              <div
                style={{
                  backgroundColor: "#f4f4f4",
                  display: "flex",
                }}
              >
                <Col className="wallet_frame_dateList">
                  {walletList.date} &nbsp;&nbsp;{walletList.time}
                </Col>
                <Col className="wallet_frame_balanceList">
                  <div>可用余额: {walletList.balance}</div>
                </Col>
              </div>
            </div>
          ))
        ) : loading ? (
          <Loader style={{ height: "100vh" }} />
        ) : (
          ""
        )}
      </div>
    )
  }
  const getUser = async () => {
    const response = await renderServices.userInfo()
    if (!response.data.account_number) {
      setBindAccount(true)
    }
  }
  useEffect(() => {
    setLoading(true)
    getBalance().catch((e) => {
      console.log(e)
    })
    getWalletList().catch((e) => {
      console.log(e)
    })
    getUser().catch((e) => {
      console.log(e)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="main_noBorder2" style={{ paddingBottom: "0px" }}>
        <Container
          style={{
            height: "82vh",
            overflowX: "unset",
            background:
              "linear-gradient(270deg, #FF6F5B 11.41%, #FB2E1B 44.63%)",
          }}
        >
          <div className="wallet_header">
            <Container>
              <Row className="wallet_header_row2">
                <Col>
                  <div>{balance}</div>
                </Col>
                <Col>
                  <div>{pendingWithdrawalAmount}</div>
                </Col>
              </Row>
              <Row className="wallet_header_row3">
                <Col>
                  <div>可用余额</div>
                </Col>
                <Col>
                  <div>等待提款</div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="wallet_frame_title">余额明细</div>
          {showListing()}
        </Container>
        {/* -- */}
        <div className="wallet-navbar">
          <Container
            className="skip-padding align-center"
            style={{ justifyContent: "center" }}
          >
            <Row>
              {
                <Col>
                  <NavLink to="/bind/account" replace state={navState}>
                    <Text value="绑定账户" className="wallet-navbar-button" />
                  </NavLink>
                </Col>
              }

              <Col>
                <NavLink to="/withdrawal" replace state={navState}>
                  <Text value="申请提现" className="wallet-navbar-button" />
                </NavLink>
              </Col>
              <Col>
                <NavLink to="/topup" replace state={navState}>
                  <Text value="账户充值" className="wallet-navbar-button" />
                </NavLink>
              </Col>
            </Row>
          </Container>
        </div>
        {/* -- */}
      </div>
    </React.Fragment>
  )
}

export default Wallet
