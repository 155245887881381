import { useState, useEffect, useRef } from "react";

const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false);
  //   useEffect(() => {
  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  //   }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback(() => {
      // console.log("called back")
    });
  }, [isFetching]);
  //   function handleScroll() {
  //     console.log("handling scroll");
  //     if (
  //       document.documentElement.scrollTop + window.innerHeight ==
  //       document.scrollingElement.scrollHeight
  //     ) {
  //       setIsFetching(true);
  //     } else {
  //       return;
  //     }
  //   }

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
