import React from "react";
import { Field, ErrorMessage } from "formik";
import { Row } from "react-bootstrap";

const Selectv2 = ({ name, label, options }) => {
  return (
    <Row className="custom-field">
      <Field
        className="form-control selectv2_option"
        placeholder={label}
        name={name}
        as="select"
      >
        <option style={{ color: "#868181" }}>{label}</option>
        {options.map((item) => {
          return (
            <option key={item.id} value={item.bank_name}>
              {item.bank_name}
            </option>
          );
        })}
      </Field>
      <ErrorMessage
        className="text-danger text-right error-message"
        name={name}
        component="div"
      />
    </Row>
  );
};

export default Selectv2;
