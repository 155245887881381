import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { ImageLoader } from "./../../../assets/loader/index";

const SplashScreenLoader = ({ style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <img
        src={ImageLoader}
        alt=""
        style={{ width: "40%", opacity: "0.6" }}
        // style={{ width: "100vh", height: "100vh" }}
      />
    </div>
  );
};
export default SplashScreenLoader;
