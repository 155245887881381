import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Navigation from "../common/navbar";
import authServices from "../../services/auth.services";
import renderServices from "../../services/render.services";
import Text from "../common/text";
import { NavLink } from "react-router-dom";
import { AvatarLogo } from "./../../assets/images/index";
import _ from "lodash";
import Iframe from "react-iframe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { LiveChatWidget, useWidgetState } from "@livechat/widget-react";

import {
  ProfileIcon1,
  ProfileIcon2,
  ProfileIcon3,
  ProfileIcon4,
  ProfileIcon5,
  ProfileIcon6,
  ProfileIcon7,
  ProfileIcon8,
  ProfileIcon9,
  ProfileIcon10,
  ProfileIcon11,
  ProfileIcon12,
} from "./../../assets/images/index";

const ProfilePage = ({ setTitle }) => {
  const [profileNavState] = useState({ navState: "/profile" });
  const [bidStatus] = useState({
    data: [
      {
        status: "已预约",
        icon: ProfileIcon2,
        title: "waiting for bid",
      },
      {
        status: "竞拍中",
        icon: ProfileIcon3,
        title: "bidding",
      },
      {
        status: "待付款",
        icon: ProfileIcon4,
        title: "waiting for payment",
      },
      {
        status: "已完成",
        icon: ProfileIcon5,
        title: "complete",
      },
      {
        status: "流拍",
        icon: ProfileIcon6,
        title: "fail",
      },
    ],
  });
  const [user, setUser] = useState({ name: "-", trade_pin: null });
  const [pinSettingUrl, setPinSettingUrl] = useState("");
  const [balance, setBalance] = useState("0");
  // const [hide, setHide] = useState("hide");
  const [visibility, setVisibility] = useState("minimized");
  const widgetState = useWidgetState();

  const navigate = useNavigate();

  const getUser = async () => {
    const response = await renderServices.userInfo();
    setUser(response.data);
    if (response.data.trade_pin) {
      setPinSettingUrl("/change/pin");
    } else {
      setPinSettingUrl("/set_pin");
    }
  };

  const getBalance = async () => {
    const response = await renderServices.userBalance();
    setBalance(response.data.wallet_balance);
  };

  useEffect(() => {
    getUser().catch((e) => {
      console.log(e);
    });
    getBalance().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <div className="main_noBorder2">
        <div className="profile_header">
          <Container>
            {/* <Row className="profile_header_row1">
              <Col className="col-10 profile_header_title">个人主页</Col>
              <Col>
                <FiSettings className="profile_header_setting" />
              </Col>
            </Row> */}
            <Row className="profile_header_row2">
              <Col className="col-3">
                <img src={AvatarLogo} className="profile_header_icon" />
              </Col>
              <Col>
                <div className="profile_header_name">{user.name || "-"}</div>
                <NavLink
                  state={profileNavState}
                  to="/vip"
                  className="non-decoration"
                  replace
                >
                  <div
                    className="profile_header_vip"
                    style={{ color: "#3E3A3A" }}
                  >
                    {user.vip_rank === null
                      ? "Vip 0"
                      : `Vip ${user.vip_rank || 0}`}
                  </div>
                </NavLink>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="container" style={{ padding: "0.5em" }}>
          <Container style={{ overflowY: "unset" }}>
            <NavLink
              className={`non-decoration`}
              to="/wallet"
              replace
              state={profileNavState}
            >
              <Row className="profile_balance">
                <Row className="profile_balance_row card-row">
                  <Col>
                    <span className="profile_balance_title">我的总资产</span>
                  </Col>
                  <Col className="profile_balance_linkFont1">
                    <span className="black-font profile_balance_linkFont2">
                      点击查看 {">"}
                    </span>
                  </Col>
                </Row>
                <Row className="profile_balance_row">
                  <Col className="col-2">
                    <img
                      src={ProfileIcon1}
                      alt="profile-icon-1"
                      className="profile-icon-1"
                    />
                  </Col>
                  <Col>
                    <div className="profile_balance_amount">￥{balance}</div>
                  </Col>
                </Row>
              </Row>
            </NavLink>
            <Row className="profile_balance">
              <div className="profile_balance_row row card-row">
                <div className="col">
                  <span className="profile_balance_title">我的竞拍</span>
                </div>
                <div className="profile_balance_linkFont1 col">
                  <NavLink
                    className="black-font profile_balance_linkFont2"
                    to="/auction/second"
                  >
                    点击查看 {">"}
                  </NavLink>
                </div>
              </div>
              {bidStatus.data.map((listing, index) => (
                <Col className="profile_bidding_listAlign" key={index}>
                  <NavLink
                    className="black-font"
                    to={"/auction/second?status=" + listing.title}
                    replace
                  >
                    <img
                      src={listing.icon}
                      alt={`profile-icon-${index}`}
                      style={{ width: 45, height: 45 }}
                    />
                    <div>
                      <Text
                        className="text-center red-font small-font"
                        value={listing.status}
                      />
                    </div>
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Container>
          <Container style={{ padding: "1em" }}>
            <NavLink
              to="/auction/first"
              className="non-decoration"
              replace
              state={profileNavState}
            >
              <Row className="profile_manu_row">
                <Col className="profile_manu_col1">
                  <Card.Img className="profile_manu_pic" src={ProfileIcon7} />
                  <Card.Text className="profile_manu_title">我的转拍</Card.Text>
                </Col>
                <Col className="profile_manu_col2">
                  <span className="black-font profile_manu_link">{">"}</span>
                </Col>
              </Row>
            </NavLink>
            {user.kyc_verification === "pending" ? (
              <Row className="profile_manu_row">
                <Col className="profile_manu_col1">
                  <Card.Img className="profile_manu_pic" src={ProfileIcon8} />
                  <Card.Text className="profile_manu_title">实名认证</Card.Text>
                </Col>
                <Col className="profile_manu_col2">
                  {user.kyc_verification === "true" ? (
                    <Card.Text className="profile_manu_status text-success">
                      已认证
                    </Card.Text>
                  ) : user.kyc_verification === "pending" ? (
                    <Card.Text className="profile_manu_status grey-font">
                      审核中
                    </Card.Text>
                  ) : (
                    <Card.Text className="profile_manu_status grey-font">
                      未认证
                    </Card.Text>
                  )}
                  <span className="black-font profile_manu_link">{">"}</span>
                </Col>
              </Row>
            ) : (
              <NavLink
                to="/verification"
                className="non-decoration"
                replace
                state={profileNavState}
              >
                <Row className="profile_manu_row">
                  <Col className="profile_manu_col1">
                    <Card.Img className="profile_manu_pic" src={ProfileIcon8} />
                    <Card.Text className="profile_manu_title">
                      实名认证
                    </Card.Text>
                  </Col>
                  <Col className="profile_manu_col2">
                    {user.kyc_verification === "true" ? (
                      <Card.Text className="profile_manu_status text-success">
                        已认证
                      </Card.Text>
                    ) : user.kyc_verification === "pending" ? (
                      <Card.Text className="profile_manu_status grey-font">
                        审核中
                      </Card.Text>
                    ) : (
                      <Card.Text className="profile_manu_status grey-font">
                        未认证
                      </Card.Text>
                    )}
                    <span className="black-font profile_manu_link">{">"}</span>
                  </Col>
                </Row>
              </NavLink>
            )}

            <NavLink
              to={pinSettingUrl}
              className="non-decoration"
              replace
              state={profileNavState}
            >
              <Row className="profile_manu_row">
                <Col className="profile_manu_col1">
                  <Card.Img className="profile_manu_pic" src={ProfileIcon9} />
                  <Card.Text className="profile_manu_title">支付密码</Card.Text>
                </Col>
                <Col className="profile_manu_col2">
                  {user.trade_pin === null ? (
                    <Card.Text className="profile_manu_status grey-font">
                      未设置
                    </Card.Text>
                  ) : (
                    <Card.Text className="profile_manu_status text-success">
                      已设置
                    </Card.Text>
                  )}

                  <span className="black-font profile_manu_link">{">"}</span>
                </Col>
              </Row>
            </NavLink>
            <NavLink
              to="/meter"
              className="non-decoration"
              replace
              state={profileNavState}
            >
              <Row className="profile_manu_row">
                <Col className="profile_manu_col1">
                  <Card.Img className="profile_manu_pic" src={ProfileIcon10} />
                  <Card.Text className="profile_manu_title">
                    我的信用分
                  </Card.Text>
                </Col>
                <Col className="profile_manu_col2">
                  <span className="black-font profile_manu_link">{">"}</span>
                </Col>
              </Row>
            </NavLink>

            <NavLink
              to="/change/password"
              className="non-decoration"
              replace
              state={profileNavState}
            >
              <Row className="profile_manu_row">
                <Col className="profile_manu_col1">
                  <Card.Img className="profile_manu_pic" src={ProfileIcon11} />
                  <Card.Text className="profile_manu_title">更换密码</Card.Text>
                </Col>
                <Col className="profile_manu_col2">
                  <NavLink
                    className="black-font profile_manu_link"
                    to="/change/password"
                  >
                    {">"}
                  </NavLink>
                </Col>
              </Row>
            </NavLink>

            <span
              onClick={() => {
                window.open(" https://direct.lc.chat/14022327/");
                // setVisibility("maximized");
              }}
              className="non-decoration"
            >
              <Row className="profile_manu_row">
                <Col className="profile_manu_col1">
                  <Card.Img className="profile_manu_pic" src={ProfileIcon12} />
                  <Card.Text className="profile_manu_title">联系客服</Card.Text>
                </Col>
                <Col className="profile_manu_col2">
                  <span className="black-font profile_manu_link">{">"}</span>
                </Col>
              </Row>
            </span>
          </Container>
        </div>
      </div>
      {/* <LiveChatWidget
        license="14022327"
        visibility={visibility}
        // visibility="minimized"
        // onNewEvent={() => {
        //   console.log("new");
        //   setVisibility("hidden");
        // }}
        onVisibilityChanged={() => {
          // if (startCount != 0) {
          //   console.log("it changed");
          //   setVisibility("minimized");
          // }
          if (widgetState.visibility === "maximized") {
            setVisibility("minimized");
          }
        }}
      /> */}
      {/* {_.isEqual(hide, "") ? (
        <div>
          <Iframe
            url="https://direct.lc.chat/14022327/"
            width="100%"
            height="100%"
            id="myId"
            className={`iframe-custom ${hide}`}
            position="fixed"
            overflow="hidden"
          ></Iframe>
          <Button
            className={`iframe-close-button ${hide}`}
            onClick={() => {
              setHide("hide");
            }}
          >
            <FontAwesomeIcon value="10" icon={faX} />
          </Button>
        </div>
      ) : (
        ""
      )} */}
    </React.Fragment>
  );
};

export default ProfilePage;
