import React, { useState, useEffect, useRef } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import httpService from "../../services/http.services";
import Text from "./text";
import config from "./../../config.json";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorMessage from "./error/errorMessage";
import { toast } from "react-toastify";
import _ from "lodash";
import CryptoJS from "crypto-js";
import common from "../../utils/common";
import useSound from "use-sound";
import clickWav from "./../../assets/sound/click.mp3";
import click2 from "./../../assets/sound/click2.mp3";

const Pin = ({ data, closePin, customFunction }) => {
  const [fullscreen] = useState(true);
  const [pinValue, setPinValue] = useState([]);
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [fifth, setFifth] = useState("");
  const [sixth, setSixth] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [currentIndex, setCurrentIndex] = useState(1);
  const [desc, setDesc] = useState("");
  const [content, setContent] = useState("");
  const [toastNotify, setToastNotify] = useState(false);
  const [play] = useSound(click2);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    switch (data.type) {
      case "deposit":
        setDesc("支付密码");
        setContent(
          "*请注意，如果在规定的拍卖时间内未能拍卖完成，本次拍卖自动流拍，所有参与者保证金将会退回，及红包无效。"
        );
        setToastNotify(true);

        break;
      case "bid":
        setDesc("房间密码");
        setContent(
          "*请注意，平台坚持公开，公平，公正原则，严厉禁止用户使用一切非本人点击出价的一切手段，手法或外挂。"
        );
        setToastNotify(true);

        break;
      case "set_trade_pin":
        setDesc("首次支付密码");
        setContent("");
        setToastNotify(true);

        break;
      case "withdrawal":
        setDesc("提现密码");
        setContent("");
        setToastNotify(true);
        break;
      default:
        setDesc("支付密码");
        setContent(
          "*请注意，如果在规定的拍卖时间内未能拍卖完成，本次拍卖自动流拍，所有参与者保证金将会退回，及红包无效。"
        );
        setToastNotify(true);

        break;
    }
  });
  function clearPin() {
    setFirst("");
    setSecond("");
    setThird("");
    setFourth("");
    setFifth("");
    setSixth("");
    setCurrentIndex(1);
    setPinValue([]);
  }
  const ClickButton = ({ value, label }) => {
    const handleValue = (index, value) => {
      switch (index) {
        case 1:
          setFirst(value);
          break;
        case 2:
          setSecond(value);
          break;
        case 3:
          setThird(value);
          break;
        case 4:
          setFourth(value);
          break;
        case 5:
          setFifth(value);
          break;
        case 6:
          setSixth(value);
          break;
        default:
          break;
      }
    };
    const handlePin = async (e) => {
      play();
      const canVibrate = window.navigator.vibrate;
      if (canVibrate) window.navigator.vibrate(50);

      e.preventDefault();
      //reset animation
      e.target.classList.remove("animate");
      e.target.classList.add("animate");
      setTimeout(function () {
        e.target.classList.remove("animate");
      }, 700);

      let value = e.currentTarget.value;

      if (value === "10") {
        if (currentIndex >= 0) {
          handleValue(currentIndex, "");
          pinValue.pop();
          setCurrentIndex(currentIndex - 1);
        }
      } else if (value === "11") {
        if (currentIndex === 6) {
          const payload = data;
          const pin = pinValue.join("");
          if (data.method === "post") {
            try {
              // Define API pin payload naming //
              if (
                _.includes(
                  ["withdrawal", "deposit", "set_trade_pin"],
                  data.type
                )
              )
                payload.trade_pin = pin;
              else payload.code = pin;
              if (_.includes(["bid_payment"], data.type)) {
                customFunction();
                return;
              }
              if (_.includes(["set_trade_pin"], data.type)) {
                // Extra payload for set trade pin //
                payload.confirm_trade_pin = pin;
              }

              // HTTP Request //
              const response = await httpService.post(
                config.apiEndPoint + data.api,
                payload
              );

              // Respond checking //x
              if (response) {
                common.sweetAlertCustom({
                  title: "通知",
                  html: response.data.message,
                  showCancelButton: false,
                  showConfirmButton: false,
                });

                closePin();
                if (_.includes(["set_trade_pin"], data.type)) {
                  localStorage.setItem("trade_pin", CryptoJS.MD5(pin));
                  if (state) {
                    navigate(state.navState, {
                      replace: true,
                      state: state,
                    });
                  } else {
                    navigate("/profile", {
                      replace: true,
                    });
                  }
                } else if (_.includes(["bid"], data.type)) {
                  localStorage.setItem("pin", pin);
                  localStorage.setItem("room_id", data.room_id || "");
                  const result = response.data.data;
                  navigate(data.link, {
                    replace: true,
                    state: {
                      data: response.data,
                      bid_id: result.bid_id,
                      ...data.navLink,
                    },
                  });
                } else {
                  const result = response.data.data;
                  navigate(data.link, {
                    replace: true,
                    state: {
                      data: response.data,
                      bid_id: result.bid_id,
                      ...data.navLink,
                    },
                  });
                }
              }
            } catch (ex) {
              setError(true);
              if (ex && ex.response.status === 422) {
                clearPin();
                const errors = ex.response.data.errors;
                setErrorMessage(errors);
              }
            }
          } else {
            // REDUNDANT //
            const response = await httpService.get(
              config.apiEndPoint + data.api
            );
            if (response) {
              // do whatever you want
            }
          }
        }
      } else {
        if (currentIndex < 6) {
          pinValue.push(value);
          handleValue(pinValue.length, value);
          setCurrentIndex(pinValue.length);
        }
      }
    };
    return (
      <button
        className={`empty-button confetti-button`}
        value={value}
        onClick={handlePin}
      >
        {label}
      </button>
    );
  };

  return (
    <Modal show={data.pin} fullscreen={fullscreen}>
      <Modal.Header className="custom-header">
        {data.closeFlag ? (
          <Button
            className="pin-close-button"
            onClick={() => {
              if (state && window.location.pathname === "/set_pin") {
                state.navState = state.previousState;
                navigate(state.navState, { replace: true, state });
              } else {
                navigate("/profile", { replace: true });
              }
            }}
          >
            <FontAwesomeIcon value="10" icon={faChevronLeft} />
          </Button>
        ) : (
          <Button
            className="pin-close-button"
            onClick={() => {
              clearPin();
              closePin();
            }}
          >
            <FontAwesomeIcon value="10" icon={faX} />
          </Button>
        )}
        <Modal.Title style={{ textAlign: "center" }}>{data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Container style={{ marginBottom: 15 }}>
          {data.balance ? (
            <Row style={{ textAlign: "center", fontSize: "2rem" }}>
              <Text value={`￥${data.balance}`} />
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Text value={`请输入您的${desc}`} />
          </Row>
        </Container>
        <Container className="pin-container" fluid>
          <input
            className="custom-pin-input"
            name="input"
            type="password"
            value={first}
            readOnly
          />
          <input
            className="custom-pin-input"
            name="input"
            type="password"
            value={second}
            readOnly
          />
          <input
            className="custom-pin-input"
            name="input"
            type="password"
            value={third}
            readOnly
          />
          <input
            className="custom-pin-input"
            name="input"
            type="password"
            value={fourth}
            readOnly
          />
          <input
            className="custom-pin-input"
            name="input"
            type="password"
            value={fifth}
            readOnly
          />
          <input
            className="custom-pin-input"
            name="input"
            type="password"
            value={sixth}
            readOnly
          />
        </Container>
        {error && (
          <Container fluid>
            {Object.keys(errorMessage).map(function (key, index) {
              // return <ErrorMessage label={errorMessage[key][0]} />;
              return (
                <span style={{ display: "block" }} key={`error-key-${index}`}>
                  {errorMessage[key].map(function (item, index) {
                    return <ErrorMessage key={`error-${index}`} label={item} />;
                  })}
                </span>
              );
            })}
          </Container>
        )}
        <Container>
          <Row>
            <Text
              value={`${content}`}
              className="red-font small-font"
              style={{ marginTop: 100 }}
            />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Container className="custom-container">
          <Row className="row-center">
            <Col>
              <ClickButton value="1" label="1" />
            </Col>
            <Col>
              <ClickButton value="2" label="2" />
            </Col>
            <Col>
              <ClickButton value="3" label="3" />
            </Col>
          </Row>
          <Row className="row-center">
            <Col>
              <ClickButton value="4" label="4" />
            </Col>
            <Col>
              <ClickButton value="5" label="5" />
            </Col>
            <Col>
              <ClickButton value="6" label="6" />
            </Col>
          </Row>

          <Row className="row-center">
            <Col>
              <ClickButton value="7" label="7" />
            </Col>
            <Col>
              <ClickButton value="8" label="8" />
            </Col>
            <Col>
              <ClickButton value="9" label="9" />
            </Col>
          </Row>
          <Row className="row-center">
            <Col>
              <ClickButton
                value="10"
                label={<FontAwesomeIcon value="10" icon={faX} />}
              />
            </Col>
            <Col>
              <ClickButton value="0" label="0" />
            </Col>
            <Col>
              <ClickButton
                value="11"
                label={<FontAwesomeIcon value="11" icon={faCheck} />}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default Pin;
