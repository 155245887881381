import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import Navigation from "../common/navbar";
import productData from "../../data-template/product.json";
import Header from "../../components/common/header";
import renderServices from "../../services/render.services";
import commonUtils from "../../utils/common";
import Spinner from "./../common/spinner";
import Carousel from "../common/carousel.custom";
import { NavLink } from "react-router-dom";
import { EmptyImage, Reward } from "./../../assets/images/index";
import Loader from "./../common/loading/loader";
import { useSearchParams } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import useInfiniteScroll from "./UseInfiniteScroll";
import ProductDetailv2 from "./ProductDetailv2";

const Marketplacev2 = ({ search, setSearch }) => {
  const [onLoad, setOnLoad] = useState(true);
  const [listItems, setListItems] = useState([]);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(6);
  const [isEnd, setIsEnd] = useState(false);
  const messageEndRef = useRef(null);
  const [productDetail, setProductDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingSubCategory, setLoadingSubCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showCategory, setShowCategory] = useState(true);
  const [subCategoryId, setSubCategoryId] = useState();
  const [productID, setProductID] = useState();

  async function fetchMoreListItems() {
    // getProducts(null, null, start, length);
    let sumStart = start + 10; // skip 10 every load
    const response = await renderServices.listProduct(
      subCategoryId,
      search,
      start,
      10
    );
    if (response.data.length > 0) {
      setListItems((listItems) => [...listItems, ...response.data]);
    } else {
      setIsEnd(true);
    }
    setStart(sumStart);
    setIsFetching(false);
    setLoading(false);
    setOnLoad(false);
  }

  const getProducts = async (sub_category_id, search, start = 0, limit) => {
    setListItems([]);
    setSubCategoryId(sub_category_id);
    setIsEnd(false);
    let sumStart = start + 10; // skip 10 every load
    const response = await renderServices.listProduct(
      sub_category_id,
      search,
      start,
      limit
    );
    if (response.data.length > 0) {
      // setListItems((listItems) => [...listItems, ...response.data]);
      setListItems(response.data);
    } else {
      setIsEnd(true);
    }
    setStart(sumStart);
    setIsFetching(false);
    setLoading(false);
    setOnLoad(false);
  };

  const getCategories = async () => {
    const response = await renderServices.listCategories();
    response.data.map((item) => {
      setCategories((categories) => [...categories, item]);
    });
    setLoadingCategory(false);
  };
  const getSubCategories = async (category_id) => {
    const response = await renderServices.listSubCategories(category_id);
    setSubCategories([]);
    response.data.sub_categories.map((item) => {
      setSubCategories((subCategories) => [...subCategories, item]);
    });
    setLoadingSubCategory(false);
    setShowCategory(false);
  };
  const handleClick = (e, category_id) => {
    setLoadingSubCategory(true);
    e.preventDefault();
    getSubCategories(category_id);
  };

  const handleSubCategoryClick = (e, sub_category_id) => {
    setStart(0);
    setLoading(true);
    e.preventDefault();
    setSubCategoryId(sub_category_id);
    getProducts(sub_category_id, search);
  };

  const scrollToBottom = () => {
    if (messageEndRef) {
      if (messageEndRef.current) {
        messageEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const handleScroll = useCallback(async () => {
    // console.log("scrolling ", document.documentElement.scrollTop);
    // console.log("inner height ", window.innerHeight);
    // console.log("scroll height ", document.scrollingElement.scrollHeight);
    if (isEnd) {
      return;
    }
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.scrollingElement.scrollHeight
    ) {
      setIsFetching(true);
      scrollToBottom();
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (!onLoad) {
      if (!isEnd) {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll, true);
      }
    }
  }, [isEnd, onLoad]);
  useEffect(() => {
    setLoading(true);
    setLoadingCategory(true);
    getCategories().catch((e) => {
      console.log(e);
    });
    // if (listItems.length = 0) {
    getProducts(null, search).catch((e) => {
      console.log(e);
    });

    // }
  }, [search]);

  return (
    <React.Fragment>
      <Navigation />
      {/* <Pin showStatus={pinModal} /> */}
      {/* <Pin title="" showStatus={modalStatus}/> */}
      {productDetail && (
        <ProductDetailv2
          show={productDetail}
          id={productID}
          setProductDetail={setProductDetail}
        />
      )}
      <div className="main">
        <div
          className="main-body"
          style={{ marginTop: "1em", borderRadius: "30px 30px 0px 0px" }}
        >
          {/* <button onClick={openModal(1)}>Pay</button> */}
          <Container className="category-list" style={{ overflowY: "unset" }}>
            <Row style={{ backgroundColor: "#f4f4f4" }}>
              {showCategory ? (
                <Carousel
                  loadingCategory={loadingCategory}
                  data={categories}
                  customClickEvent={handleClick}
                />
              ) : (
                <>
                  {/* <Card
                    onClick={(e) => {
                      console.log("back btn");
                      setShowCategory(true);
                      getProducts(null, search);
                    }}
                    key={`back-button}`}
                    className="custom-card"
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      style={{
                        color: "#C4C4C4",
                        marginTop: "50%",
                        marginBottom: "30%",
                      }}
                    />
                    <Card.Text
                      style={{ marginTop: "auto" }}
                      className="text-center tiny-font"
                    >
                      返回
                    </Card.Text>
                  </Card> */}
                  <Carousel
                    loadingCategory={loadingSubCategory}
                    data={subCategories}
                    customClickEvent={handleSubCategoryClick}
                    backButton={true}
                    backButtonColor={"#C4C4C4"}
                    backButtonLink={"/marketplace"}
                    setShowCategory={setShowCategory}
                    getProducts={getProducts}
                    search={search}
                  />
                </>
              )}
            </Row>
            <Row
              className="title-header"
              style={{ backgroundColor: "#f4f4f4" }}
            >
              <Col xs={9}>
                <h2>
                  <span className="darkgrey-font">平台</span>
                  <span className="red-font">推荐</span>
                </h2>
              </Col>
              <Col
                xs={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <img src={Reward} alt="" className="reward-icon" />
              </Col>
            </Row>
            <Row
              xs={1}
              md={2}
              className="g-4"
              style={{ backgroundColor: "#f4f4f4" }}
              // onScroll={() => {
              //   console.log("onscroll ", onLoad);
              //   if (!isEnd) {
              //     handleScroll();
              //   }
              // }}
            >
              {!loading && listItems.length > 0 ? (
                listItems.map((product) => (
                  <>
                    <Col
                      className={`g-2`}
                      key={`product-${product.id}`}
                      xs={6}
                      // style={{ boxShadow: "0px 2px 10px 1px rgb(0 0 0 / 25%)" }}
                    >
                      {/* <NavLink
                        to={`/product/${product.id}`}
                        style={{ color: "inherit", textDecoration: "none" }}
                      > */}
                      <Card
                        onClick={() => {
                          setProductID(product.id);
                          setProductDetail(true);
                        }}
                      >
                        <Card.Img
                          className="product-image"
                          variant="top"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = EmptyImage;
                          }}
                          src={
                            product.mainMediaUrl && product.mainMediaUrl !== ""
                              ? product.mainMediaUrl
                              : EmptyImage
                          }
                        />
                        <Card.Body>
                          <Card.Title className="small-font">
                            {product.name.substring(0, 80)}
                          </Card.Title>
                          <Row
                            className={`g-0`}
                            style={{
                              marginLeft: "0 !important",
                              bottom: "0",
                              marginBottom: "0.5em",
                            }}
                          >
                            <Col
                              className="x-small-font"
                              style={{
                                fontWeight: "bold",
                                color: "#878585",
                              }}
                            >
                              目前价格
                            </Col>
                            <Col
                              className="small-font"
                              style={{
                                fontWeight: "bold",
                                color: "#FF4F28",
                              }}
                            >
                              ¥ {product.original_price}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                      {/* </NavLink> */}
                    </Col>
                  </>
                ))
              ) : loading ? (
                <Loader />
              ) : (
                <Card style={{ backgroundColor: "#f4f4f4", border: "none" }}>
                  <Card.Body style={{ textAlign: "center" }}>
                    <Card.Title>暂无数据显示</Card.Title>
                  </Card.Body>
                </Card>
              )}
              {isFetching && <Loader />}
            </Row>
            <span ref={messageEndRef}></span>
            {/* {isFetching ? (
              <span style={{ marginBottom: "5em" }}>索取更多。。。</span>
            ) : (
              ""
            )} */}
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
  // return (
  //   <>
  //     <ul
  //       className="list-group mb-2"
  //       style={{ height: "100px", overflow: "auto" }}
  //       onScroll={() => {
  //         if (!isEnd) {
  //           handleScroll();
  //         }
  //       }}
  //     >
  //       {listItems.map((listItem) => (
  //         <li className="list-group-item">List Item {listItem.id}</li>
  //       ))}
  //       {isFetching && <Loader />}
  //     </ul>
  //   </>
  // );
};

export default Marketplacev2;
