import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import CountDownCustom from "../common/countdown.custom"
import { NavLink } from "react-router-dom"

const AuctionList = ({ data, customClickEvent, navState }) => {
  if (Object.keys(data).length > 0 && data) {
    return (
      <div key={data.bid_id} className="selectItem_listingRow">
        <Row
          className="g-0"
          onClick={(e) => customClickEvent(e, data, navState)}
        >
          <Col className="col-4">
            <img src={data.image} className="auction_listing_pic" alt="" />
          </Col>
          <Col className="auction_pic_col">
            <Row className="g-0">
              {/* <NavLink to="/vip" className="non-decoration"> */}
              <Col
                onClick={(event) => {
                  // event.stopPropagation();
                }}
              >
                <div className="auction_listing_vipLv">VIP {data.vip}</div>
              </Col>
              {/* </NavLink> */}
              <Col>
                <div
                  className="auction_listing_status"
                  style={{
                    backgroundColor: "#F52F14",
                    // marginLeft: "-2.4em",
                    // marginRight: "1.4em",
                  }}
                >
                  {data.bid_status_translation}
                </div>
              </Col>
              {/* <Col>
                <div className="auction_listing_status">{data.status}</div>
              </Col> */}
              <Col className="auction_roomId_col">
                <div className="auction_listing_roomId">ID : {data.code}</div>
              </Col>
            </Row>
            <Row className="g-0">
              <div
                className="auction_listing_description"
                style={{ color: "#484646" }}
              >
                <b>{data.product_name}</b>
              </div>
            </Row>
          </Col>
        </Row>
        <Row className="auction_info_row g-0">
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey">起拍价：</div>
          </Col>
          <Col className="auction_info_col" style={{ marginLeft: "-15px" }}>
            <div
              className="auction_info_wording_red auction_wording_style1"
              style={{ fontSize: "15px" }}
            >
              ￥{data.start_price}
            </div>
          </Col>
          <Col className="auction_info_col" style={{ marginLeft: "15px" }}>
            <div className="auction_info_wording_grey">
              {data.start_status === "false" ? `距离结束：` : `距离开始：`}
            </div>
          </Col>
          <Col className="auction_info_col" style={{ marginLeft: "-15px" }}>
            <div className="auction_info_wording_grey auction_wording_style2">
              {data.hide_at === "true" ? (
                `已结束`
              ) : (
                <CountDownCustom
                  date={data.countdown}
                  completeText={
                    data.start_status === "true" ? "进入拍卖" : "已经完毕"
                  }
                ></CountDownCustom>
              )}
            </div>
          </Col>
        </Row>
        <Row className="auction_info_row g-0">
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey">保证金：</div>
          </Col>
          <Col className="auction_info_col" style={{ marginLeft: "-15px" }}>
            <div className="auction_info_wording_grey auction_wording_style1">
              ￥{data.reserve_price}
            </div>
          </Col>
          <Col className="auction_info_col" style={{ marginLeft: "15px" }}>
            <div className="auction_info_wording_grey">参与人数：</div>
          </Col>
          <Col className="auction_info_col" style={{ marginLeft: "-15px" }}>
            <div className="auction_info_wording_grey auction_wording_style2">
              {data.participant_number}
            </div>
          </Col>
        </Row>
      </div>
    )
  } else {
    return (
      <Container>
        <Row>此时间没拍卖</Row>
      </Container>
    )
  }
}

export default AuctionList
