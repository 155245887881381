import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FormikForm from "../common/formik.form";
import * as Yup from "yup";
import bankServices from "../../services/auth.services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import renderServices from "../../services/render.services";
import common from "../../utils/common";
import Loader from "./../common/loading/loader";
import Input from "./../common/input";
import Select from "./../common/Selectv2";

async function onSubmit(data, setFieldError, navigate) {
  try {
    console.log("data ", data);
    const result = await bankServices.bankInfo({ ...data });
    common.sweetAlertCustom({
      title: "通知",
      html: result.data.message,
      showCancelButton: false,
      showConfirmButton: false,
    });
    navigate("/wallet", { replace: true });
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors;

      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item]);
        });
      }

      // this.setState({ errors })
      // console.log(`Response Error : ${JSON.stringify(errors)}`);
    }
  }
}
const BindAccount = () => {
  const [data, setData] = useState({
    account_number: "",
    holder_name: "",
  });

  const [banks, setBanks] = useState([]);
  const changePinSchema = Yup.object({
    holder_name: Yup.string().required("需要填入您的银行名字"),
  });
  const navigate = useNavigate();
  const [isBinded, setIsBinded] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifySchema = Yup.object({
    name: Yup.string()
      .required("需要填入您的全名")
      .matches(/(\p{Script=Hani})+/gu, "只能输入中文字体"),

    ic_number: Yup.number()
      .typeError("身份证号格式必须是数字")
      .required("需要填入您的身份证号"),
  });
  useEffect(() => {
    setLoading(true);

    getUser().catch((e) => {
      console.log(e);
    });
    getBanks().catch((e) => {
      console.log(e);
    });
  }, []);

  const getUser = async () => {
    const response = await renderServices.userInfo();
    if (response.data.account_number != null) {
      setIsBinded(true);
      setData({
        account_number: response.data.account_number,
        holder_name: response.data.payment_name,
      });
    }
    setLoading(false);
  };
  const getBanks = async () => {
    const response = await renderServices.listBank();
    setBanks(response.data.data);
  };
  return (
    <React.Fragment>
      <Container className="container-full-white">
        <Formik
          initialValues={data}
          enableReinitialize={true}
          validationSchema={changePinSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            onSubmit(values, setFieldError, navigate);
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, errors, isSubmitting, isValid }) => (
            <>
              {!loading ? (
                !isBinded ? (
                  <Form style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
                    {/* {FormikForm.renderInput(
                      "account_number",
                      "请输入您的银行号码",
                      "number"
                    )} */}
                    <Input
                      name={"account_number"}
                      label={"请输入您的银行号码"}
                      type={"text"}
                      onChange={(e) => {
                        const result = e.target.value.replace(/\D/g, "");
                        setFieldValue("account_number", result);
                      }}
                    />
                    {FormikForm.renderSelectv2(
                      "holder_name",
                      "请选择您的银行",
                      banks,
                      setFieldValue
                    )}
                    {FormikForm.renderButton("立即绑定", isSubmitting, isValid)}
                  </Form>
                ) : (
                  <Form style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
                    <Input
                      name={"account_number"}
                      label={"请输入您的银行号码"}
                      type={"text"}
                      defaultValue={data.account_number}
                      onChange={(e) => {
                        const result = e.target.value.replace(/\D/g, "");
                        setFieldValue("account_number", result);
                      }}
                    />
                    {FormikForm.renderSelectv2(
                      "holder_name",
                      "请选择您的银行",
                      banks
                    )}
                    <div
                      style={{
                        color: "#3B824F",
                        marginTop: "1em",
                        fontSize: "0.9rem",
                      }}
                    >
                      已绑定账号
                    </div>
                    {FormikForm.renderButton("更新", isSubmitting, isValid)}
                  </Form>
                )
              ) : (
                <Loader
                  style={{
                    position: "fixed",
                    left: "44%",
                    top: "44%",
                  }}
                />
              )}
            </>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default BindAccount;
