import React, { useState } from "react"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import FormikForm from "../common/formik.form"
import * as Yup from "yup"
import bankServices from "../../services/auth.services"
import { NavLink, useNavigate } from "react-router-dom"
import Text from "./../common/text"
import authServices from "../../services/auth.services"
import { toast } from "react-toastify"
import packageJson from "./../../../package.json"
import common from "../../utils/common"

import {
  QuestionIcon,
  GPSIcon,
  BriefCaseIcon,
} from "./../../assets/images/index"

async function onSubmit(data, setFieldError) {
  try {
    await bankServices.bankInfo({ ...data })
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors

      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item])
        })
      }

      // this.setState({ errors })
    }
  }
}

function pendingFeature() {
  common.sweetAlertCustom({
    title: "通知",
    html: `此功能开发中`,
    showCancelButton: false,
    showConfirmButton: false,
  })
}

const Setting = () => {
  const [navState] = useState({ navState: "/setting" })
  const [data, setData] = useState({
    account_number: "",
    holder_name: "",
  })
  const [version, setVersion] = useState(0)
  const navigate = useNavigate()

  const changePinSchema = Yup.object({
    account_number: Yup.string().required("需要填入您的银行号码"),
    holder_name: Yup.string().required("需要填入您的银行名字"),
  })

  return (
    <React.Fragment>
      <Container
        className="container-full background-grey"
        style={{ padding: "1em" }}
      >
        <Row
          className="profile_manu_row"
          onClick={() => {
            navigate("/suggestion", { replace: true, state: navState })
          }}
        >
          <Col className="profile_manu_col1">
            <Card.Img className="profile_manu_pic" src={QuestionIcon} />
            <Card.Text className="profile_manu_title">问题反馈</Card.Text>
          </Col>
          <Col className="profile_manu_col2 profile_manu_link">
            {/* <NavLink className="black-font" to="/auction/first"> */}
            <span className="profile_manu_link">{">"}</span>
            {/* </NavLink> */}
          </Col>
        </Row>
        <Row className="profile_manu_row">
          <Col className="profile_manu_col1">
            <Card.Img className="profile_manu_pic" src={GPSIcon} />
            <Card.Text className="profile_manu_title">清空缓存</Card.Text>
          </Col>
          <Col className="profile_manu_col2" onClick={pendingFeature}>
            <span className="profile_manu_link">{">"}</span>
          </Col>
        </Row>
        <Row className="profile_manu_row">
          <Col className="profile_manu_col1">
            <Card.Img className="profile_manu_pic" src={BriefCaseIcon} />
            <Card.Text className="profile_manu_title">当前版本</Card.Text>
          </Col>
          <Col className="profile_manu_col2" onClick={pendingFeature}>
            <Card.Text className="profile_manu_status text-success">
              {packageJson.version}
            </Card.Text>
            <span className="profile_manu_link">{">"}</span>
          </Col>
        </Row>

        <Row
          className="topup-row-grey topup-row-button"
          style={{ marginTop: "20em" }}
          onClick={(e) => {
            authServices.logout()
            navigate("/")
          }}
        >
          <Col className="topup-col-button">
            <Button className="topup-custom-button">
              <Text value="登出" />
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Setting
