import http from "./http.services"
import auth from "./auth.services"
import config from "../config.json"

async function register(data) {
  return await http.post(config.apiEndPoint + "/register", {
    phone_number: data.phone_number || "",
    password: data.password || "",
    invitation_code: data.invitation_code || "",
  })
}

async function getProfile() {
  http.setToken(auth.getToken())
  const response = await http.get(config.apiEndPoint + "/profile")
  return response
}

async function verify(data) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/set_kyc", {
    name: data.name,
    ic_number: data.ic_number,
  })
  return response
}

async function bid(bid_id) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/bid_price", {
    bid_id: bid_id,
  })
  return response
}
async function changePassword(payload) {
  http.setToken(auth.getToken())
  const response = await http.post(config.apiEndPoint + "/change_password", {
    password: payload.password,
    new_password: payload.new_password,
    confirm_new_password: payload.confirm_new_password,
  })
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  register,
  getProfile,
  verify,
  bid,
  changePassword,
}
