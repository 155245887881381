import React from "react"

const Span = ({ value, className, style }) => {
  return (
    <span className={className} style={style}>
      {value}
    </span>
  )
}

export default Span
