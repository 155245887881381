import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Row } from "react-bootstrap";
import EyeSlash from "../../assets/images/eye-slash.svg";
import Eye from "../../assets/images/eye.svg";

const Input = ({
  name,
  label,
  type,
  autoComplete,
  className,
  showEye,
  ...rest
}) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  return (
    <Row className="custom-field">
      {/* <label htmlFor={name}> {label} </label> */}

      <Field
        className={
          showHidePassword ? "form-control" : `form-control ${className}`
        }
        placeholder={label}
        name={name}
        type={type}
        autoComplete={autoComplete}
        // readonly
        // onfocus="this.removeAttribute('readonly');"
        {...rest}
      />

      {showEye ? (
        <>
          {showHidePassword ? (
            <img
              src={Eye}
              onClick={() => changeShowHidePassword(!showHidePassword)}
              className="input-password-eye"
            />
          ) : (
            <img
              src={EyeSlash}
              onClick={() => changeShowHidePassword(!showHidePassword)}
              className="input-password-eye"
            />
          )}
        </>
      ) : (
        ""
      )}
      <ErrorMessage
        className="text-danger text-right no-padding error-message"
        name={name}
        component="div"
      />
    </Row>
  );
};

export default Input;
