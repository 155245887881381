import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FormikForm from "../common/formik.form";
import * as Yup from "yup";
import pinServices from "../../services/auth.services";
import { toast } from "react-toastify";
import _ from "lodash";
import userServices from "../../services/user.services";
import common from "../../utils/common";
import EyeSlash from "../../assets/images/eye-slash.svg";
import Eye from "../../assets/images/eye.svg";

async function onSubmit(
  data,
  setFieldError,
  setSubmitting,
  resetForm,
  defaultData
) {
  try {
    const response = await userServices.changePassword({ ...data });
    if (response) {
      common.sweetAlertCustom({
        title: "通知",
        html: response.data.message,
        showCancelButton: false,
        showConfirmButton: false,
      });
      resetForm({ defaultData });
      return true;
    }
  } catch (ex) {
    if (ex) {
      if (ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      }
    }
  }
  setSubmitting(false);
}

const ChangePassword = () => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [data, setData] = useState({
    password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const changePasswordSchema = Yup.object({
    password: Yup.string().required("需要填入旧的密码"),
    new_password: Yup.string().required("需要填入新的密码"),
    confirm_new_password: Yup.string()
      .required("需要填入相同新的密码")
      .oneOf([Yup.ref("new_password"), null], "新密码必須一樣"),
  });

  return (
    <React.Fragment>
      <Container
        className="container-full-white"
        style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}
      >
        <Formik
          initialValues={data}
          validationSchema={changePasswordSchema}
          onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
            onSubmit(values, setFieldError, setSubmitting, resetForm, data);
          }}
        >
          {({ setFieldValue, errors, isSubmitting, isValid }) => (
            <Form>
              {showHidePassword ? (
                <div className="change-password-form-eye">
                  <img
                    src={Eye}
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    className="input-password-eye"
                  />
                </div>
              ) : (
                <div className="change-password-form-eye">
                  <img
                    src={EyeSlash}
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    className="input-password-eye"
                  />
                </div>
              )}
              {FormikForm.renderInput(
                "password",
                "请输入旧的密码",
                "text",
                "new-password",
                showHidePassword ? "" : "key"
              )}
              {FormikForm.renderInput(
                "new_password",
                "请输入新的密码",
                "text",
                "new-password",
                showHidePassword ? "" : "key"
              )}
              {FormikForm.renderInput(
                "confirm_new_password",
                "请再次输入新的密码",
                "text",
                "new-password",
                showHidePassword ? "" : "key"
              )}
              <Row className="custom-button">
                <button
                  type="submit"
                  className="btn"
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? "更换密码" : "更换密码"}
                </button>
              </Row>
              {/* {FormikForm.renderButton("更换密码", isSubmitting, isValid)} */}
            </Form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default ChangePassword;
