import React, { useEffect, useState, useRef, createRef } from "react";
import { useLocation } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import Text from "./../common/text";
import ChatMessage from "./../common/chat.message";
import BottomBar from "./../common/bottom.bar";
import renderSvc from "../../services/render.services";
import userSvc from "../../services/user.services";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "./../common/loading/loader";
import ToastCustom from "./../common/toast-custom";
import { EmptyImage } from "./../../assets/images/index";
import common from "../../utils/common";
import { useNavigate } from "react-router-dom";
import config from "../../config.json";

import Pusher from "pusher-js";
Pusher.logToConsole = false;

const BiddingProcess = ({ setTitle }) => {
  const [navState] = useState({ navState: "/bidding/process" });
  const [loading, setLoading] = useState(false);
  const [bidData, setBidData] = useState({});
  const [bidHistory, setBidHistory] = useState([]);
  const [nextBidPrice, setNextBidPrice] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonName, setButtonName] = useState(`出价`);
  const messagesEndRef = useRef();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [headerClass, setHeaderClass] = useState("");

  const handleScroll = (event) => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 10) setHeaderClass("header-appear stick-top");
    else setHeaderClass("header-disappear");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    setLoading(true);
    retrieveBidDetail();
    if (_.isEmpty(bidHistory)) {
      retrieveBidMessage();
    }

    const pusher = new Pusher("ac651b7c7e443240ee36", {
      cluster: "ap1",
      //    encrypted: true,
      authEndpoint: config.socketEndPoint,
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          Accept: "application/json",
        },
        params: {
          bid_id: state.bid_id,
        },
      },
    });

    const channel = pusher.subscribe("presence-bid." + state.bid_id);
    const count = channel.members.count;

    var socketId = null;
    pusher.connection.bind("connected", () => {
      socketId = pusher.connection.socket_id;
    });
    channel.bind("bid-event", (data) => {
      const result = data.data;
      if (result) {
        if (_.includes(["true"], result.able_to_bid)) {
          setButtonDisable(false);
        }
        setBidHistory(result.bid_price_history);
        setNextBidPrice(result.next_bid_price);
        scrollToBottom();

        if (
          _.includes(["false"], result.able_to_bid) &&
          !_.isEmpty(result.winner_username)
        ) {
          if (
            _.isEqual(result.winner_username, localStorage.getItem("username"))
          ) {
            const url =
              result.type === "bid"
                ? `/auction/second?status=waiting for payment`
                : `/auction/first?status=waiting for payment`;
            setTitle("");
            common.sweetAlertCustom({
              title: "通知",
              html: biddingSuccess({
                title: `恭喜您，拍的此宝`,
                total_bonus_amount: result.total_bonus_amount,
                product_name: result.name,
                image: result.image,
                url: url,
              }),
              showCancelButton: false,
              showConfirmButton: false,
              confirmButtonColor: "#f91411",
              confirmButtonText: "前往查看",
              redirect_url: url,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          } else {
            const url =
              result.type === "bid"
                ? `/auction/second?status=fail`
                : `/auction/first?status=fail`;
            setTitle("");
            common.sweetAlertCustom({
              title: "通知",
              html: biddingSuccess({
                title: `很遗憾，你的物品已流拍`,
                total_bonus_amount: result.total_bonus_amount,
                product_name: result.name,
                image: result.image,
                url: url,
              }),
              showCancelButton: false,
              showConfirmButton: false,
              confirmButtonColor: "#f91411",
              confirmButtonText: "前往查看",
              redirect_url: url,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }

          setButtonName(`竞拍已结束`);
        }
      }
    });
  }, []);

  const handleClick = async (e, data, setClickCount) => {
    // Error Message Handling //
    const alertMsg = [
      "操作过快，请重复拍卖",
      "您是上次的拍者",
      "网络异常，请重复拍卖",
    ];

    try {
      // If disable button detect not allow process //
      if (buttonDisable) return;

      e.preventDefault();
      const response = await userSvc.bid(data);
      if (response) {
        const result = response.data;
        setClickCount((clickCount) => clickCount + 1);
        if (result.status === "success") {
          setClickCount(0);
          if (result.data.win_status === "fail") {
            // common.sweetAlertCustom(
            //   {
            //     title: "通知",
            //     html: response.data.message,
            //     showCancelButton: false,
            //     showConfirmButton: false,
            //   },
            //   scrollToBottom
            // )
          }
        }
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        if (clickCount >= 3) {
          const randNum = Math.floor(Math.random() * 3); //
          common.sweetAlertCustom({
            title: "通知",
            html: alertMsg[randNum],
            showCancelButton: false,
            showConfirmButton: false,
          });
          setClickCount(0);
        }
      }
    }
  };

  const biddingSuccess = ({
    title,
    image,
    product_name,
    total_bonus_amount,
    url,
  }) => {
    return (
      <Container>
        <Row className="text-center medium-font">
          <Text value={title || ""} className="red-font" />
        </Row>
        <Row>
          <img
            src={image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = EmptyImage;
            }}
            alt=""
            style={{ height: "50%", width: "80%", margin: "0 auto" }}
          />
        </Row>
        <Row className="text-center large-font">
          <Text
            style={{
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            value={product_name ? product_name : ""}
            className=""
          />
        </Row>
        <Row className="text-center medium-font">
          <Text value={`获得出价红包奖励`} className="grey-font" />
        </Row>
        <Row className="text-center large-font">
          <Text
            value={total_bonus_amount ? `￥ ${total_bonus_amount}` : ""}
            className="red-font"
            style={{ fontWeight: "bold" }}
          />
        </Row>
        <Row
          className="custom-button"
          style={{ width: "150px", margin: "0 auto", height: "40px" }}
        >
          <a href={url}>
            <Text value="前往查看" />
          </a>
        </Row>
      </Container>
    );
  };
  const retrieveBidMessage = async () => {
    const response = await renderSvc.getBiddingProcess(state.bid_id);
    setBidHistory(response.data.bid_price_history);
    // if (bidHistory.length > 0)
    scrollToBottom();
  };

  const retrieveBidDetail = async () => {
    try {
      const response = await renderSvc.getBiddingProcess(state.bid_id);
      setBidData(response.data);
      setTitle(response.data.created_at);
      setNextBidPrice(response.data.next_bid_price);
      setLoading(false);
    } catch (ex) {
      if (ex && ex.response.status === 400) {
        navigate("/bidding/room", {
          replace: true,
          state: { navState: "/dashboard" },
        });
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  // channel.bind("pusher:subscription_succeeded", (members) => {
  //   alert("successfully subscribed!")
  // })

  if (!loading) {
    return (
      <React.Fragment>
        <BottomBar
          customClickEvent={handleClick}
          bid_id={state.bid_id || ""}
          price={`￥ ${nextBidPrice}`}
          setClickCount={setClickCount}
          buttonDisable={buttonDisable}
          setButtonDisable={setButtonDisable}
          buttonName={buttonName}
        />
        <Row
          className={`bidding-process-product small-font ${headerClass} g-0`}
        >
          <Col>
            <img
              className="bidding-process-image"
              src={bidData.image || EmptyImage}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = EmptyImage;
              }}
            />
          </Col>
          <Col style={{ marginLeft: "1em" }}>
            <Row className="g-0">
              <Text
                value={bidData.name ? bidData.name.substring(0, 50) : ""}
                className="small-font"
              />{" "}
            </Row>
            <Row className="g-0" style={{ marginTop: 15 }}>
              <Col>
                <Text value={`ID: ${bidData.code}`} className="tiny-font" />
              </Col>
              <Col>
                <Text value={bidData.bid_id} className="tiny-font" />
                <Text
                  value={`VIP ${bidData.min_vip || 0}`}
                  className="tiny-font gold-rounded-box"
                />
              </Col>
            </Row>
            <Row className="g-0">
              <Col>
                <Text value="起拍价 ：" className="tiny-font" />
                <Text
                  value={`￥ ${bidData.start_price || 0} `}
                  className="tiny-font"
                />
              </Col>
              {/* <Col>
                <Text
                  value={`￥ ${bidData.start_price || 0} `}
                  className="tiny-font"
                />
              </Col> */}
            </Row>
            <Row className="g-0">
              <Col>
                <Text value="加价幅度 ：" className="tiny-font" />
                <Text
                  value={`￥ ${bidData.bid_price || 0}`}
                  className="tiny-font"
                />
              </Col>
              {/* <Col>
                <Text
                  value={`￥ ${bidData.bid_price || 0}`}
                  className="tiny-font"
                />
              </Col> */}
            </Row>
            <Row className="g-0">
              <Col>
                <Text value="保留价 ：" className="tiny-font" />
                <Text
                  value={`￥ ${bidData.warranty_price || 0}`}
                  className="tiny-font"
                />
              </Col>
              {/* <Col>
                <Text
                  value={`￥ ${bidData.reserve_price || 0}`}
                  className="tiny-font"
                />
              </Col> */}
            </Row>
            {/* <Row>
                  <Col>
                    <Text value="拍卖时间 ：" className="tiny-font" />
                  </Col>
                  <Col>
                    <Text
                      value={`${bidData.created_at || "-"}`}
                      className="tiny-font"
                    />
                  </Col>
                </Row> */}
          </Col>
        </Row>
        <Container
          className="skip-padding container-full"
          style={{ height: "70vh" }}
        >
          <Container>
            {/* <ChatMessage position="right" />
          <ChatMessage position="left" />
          <ChatMessage position="right" /> */}
            {bidHistory.length > 0
              ? bidHistory.map((item, index, row) => (
                  <ChatMessage
                    key={`chat-message-${index}`}
                    index={index}
                    lastIndex={bidHistory.length - 0}
                    data={item}
                  />
                ))
              : ""}
            <div style={{ height: "5em", width: "100%" }}></div>
            <span
              style={{ height: "2em", marginTop: "1em" }}
              ref={messagesEndRef}
            />
          </Container>
        </Container>
      </React.Fragment>
    );
  } else if (loading) {
    return <Loader style={{ minHeight: "calc(100vh - 70px)" }} />;
  } else {
    return "";
  }
};

export default BiddingProcess;
