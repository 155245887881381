import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FormikForm from "../common/formik.form";
import * as Yup from "yup";
import pinServices from "../../services/auth.services";
import userServices from "../../services/user.services";
import _ from "lodash";
import common from "../../utils/common";
import EyeSlash from "../../assets/images/eye-slash.svg";
import Eye from "../../assets/images/eye.svg";

async function onSubmit(
  data,
  setFieldError,
  setSubmitting,
  resetForm,
  defaultData
) {
  try {
    const response = await pinServices.pinNumber({ ...data });
    if (response) {
      common.sweetAlertCustom({
        title: "通知",
        html: response.data.message,
        showCancelButton: false,
        showConfirmButton: false,
      });
      resetForm({ defaultData });
      return true;
    }
  } catch (ex) {
    if (ex) {
      if (ex.response.status === 422) {
        const errors = ex.response.data.errors;

        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      }
    }
  }
  setSubmitting(false);
}

const ChangePin = ({ setTitle }) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [data] = useState({
    trade_pin: "",
    new_trade_pin: "",
    confirm_new_trade_pin: "",
  });

  const changePinSchema = Yup.object({
    trade_pin: Yup.number()
      .typeError("支付密码格式必须是数字")
      .required("需要填入旧的支付密码"),
    new_trade_pin: Yup.number()
      .typeError("支付密码格式必须是数字")
      .required("需要填入新的支付密码"),
    confirm_new_trade_pin: Yup.number()
      .typeError("支付密码格式必须是数字")
      .required("需要填入相同新的支付密码")
      .oneOf([Yup.ref("new_trade_pin"), null], "新支付密码必須一樣"),
  });

  useEffect(() => {
    validateProfile();
  });

  const validateProfile = async () => {
    const { data: response } = await userServices.getProfile();
    const profile = response.data;
    if (!_.isEmpty(profile.trade_pin)) {
      // setTitle(`更新支付密码`);
    }
  };

  return (
    <React.Fragment>
      <Container
        className="container-full-white"
        style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}
      >
        <Formik
          initialValues={data}
          validationSchema={changePinSchema}
          onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
            onSubmit(values, setFieldError, setSubmitting, resetForm, data);
          }}
        >
          {({ setFieldValue, errors, isSubmitting, isValid }) => (
            <Form>
              {showHidePassword ? (
                <div className="change-password-form-eye">
                  <img
                    src={Eye}
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    className="input-password-eye"
                  />
                </div>
              ) : (
                <div className="change-password-form-eye">
                  <img
                    src={EyeSlash}
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    className="input-password-eye"
                  />
                </div>
              )}
              {FormikForm.renderInput(
                "trade_pin",
                "请输入旧的支付密码",
                "text",
                "new-password",
                showHidePassword ? "" : "key"
              )}
              {FormikForm.renderInput(
                "new_trade_pin",
                "请输入新的六位支付密码",
                "text",
                "new-password",
                showHidePassword ? "" : "key"
              )}
              {FormikForm.renderInput(
                "confirm_new_trade_pin",
                "请再次输入新的六位支付密码",
                "text",
                "new-password",
                showHidePassword ? "" : "key"
              )}
              {/* {FormikForm.renderButton("确认", isSubmitting, isValid)} */}
              <Row className="custom-button">
                <button
                  type="submit"
                  className="btn"
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? "确认" : "确认"}
                </button>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default ChangePin;
