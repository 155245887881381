import React from "react"
import ReactDOM from "react-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import "react-phone-number-input/style.css"
import "bootstrap/dist/css/bootstrap.min.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import logger from "./services/log.services"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { Favicon16, Favicon32 } from "./assets/favicon.ico/index"
// Favicon //
import { FaviconSix, SplashScreen } from "./assets/images/index"
import {
  iPhone5Splash,
  iPhone6Splash,
  iPhonePlusSplash,
  iPhoneXSplash,
  iPhoneXRSplash,
  iPhoneXSMaxSplash,
  iPhone12,
  iPhone13,
  iPhone13ProMax,
  iPhone7,
  iPhone11ProMax,
  iPhone12ProMax,
} from "./assets/splashscreens/index"

logger.init()

const rootElement = document.getElementById("root")

ReactDOM.render(
  <BrowserRouter>
    <HelmetProvider>
      <Helmet>
        <title>京东拍卖</title>
        <meta name="description" content="欢迎使用京东拍卖商城" />
        <meta name="theme-color" content="#f84e4e" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="HandheldFriendly" content="true" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="京东拍卖" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="apple-touch-icon" href={Favicon16} />
        <link rel="icon" type="image/png" href={Favicon16} sizes="16x16" />
        <link rel="icon" type="image/png" href={Favicon32} sizes="32x32" />
        <link
          href={iPhone5Splash}
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhone6Splash}
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhonePlusSplash}
          media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhoneXSplash}
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhoneXRSplash}
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhoneXSMaxSplash}
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhone12}
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhone13}
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href={iPhone13ProMax}
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        {/* iPhone 8, 7, 6s, 6 (750px x 1334px) */}
        <link
          href={iPhone7}
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        />
        {/* iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px)  */}
        {/* <link
          href={iPhone7Plus}
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
        ></link> */}
        {/* iPhone 12 (1170 x 2532px) */}
        <link
          href={iPhone12}
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 3)"
        />
        {/* iPhone 11 Pro Max (1242 x 2688px) */}
        <link
          href={iPhone11ProMax}
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 3)"
        />
        {/* iPhone 13 (1170 x 2532px) */}
        <link
          href={iPhone13}
          rel="apple-touch-startup-image"
          media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)"
        />
        {/* iPhone 12 Pro Max (1284 x 2778px) */}
        <link
          href={iPhone12ProMax}
          rel="apple-touch-startup-image"
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 2)"
        />
      </Helmet>
    </HelmetProvider>
    <App />
  </BrowserRouter>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
