import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FormikForm from "../common/formik.form";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import common from "../../utils/common";
import renderServices from "../../services/render.services";

async function onSubmit(
  data,
  setFieldError,
  navigate,
  setSubmitting,
  setFieldValue
) {
  try {
    common.sweetAlertCustom({
      title: "通知",
      html: "谢谢您的反馈",
      showCancelButton: false,
      showConfirmButton: false,
    });
    setFieldValue("message", "");
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors;

      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item]);
        });
      }
    }
  }
  setSubmitting(false);
}

const Suggestion = () => {
  const navigate = useNavigate();
  const [characterCount, setCharacterCount] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const verifySchema = Yup.object({
    message: Yup.string().required("请输入反馈意见").max(300, "最多300条字"),
  });

  useEffect(() => {
    getUser().catch((e) => {
      console.log(e);
    });
  }, []);

  const getUser = async () => {
    const response = await renderServices.userInfo();
    setPhoneNumber(response.data.phone_number);
  };

  return (
    <React.Fragment>
      <Container>
        <Row className="basic-form g-0">
          <Formik
            initialValues={{ message: "" }}
            validationSchema={verifySchema}
            onSubmit={(
              values,
              { setSubmitting, setFieldError, setFieldValue }
            ) => {
              onSubmit(
                values,
                setFieldError,
                navigate,
                setSubmitting,
                setFieldValue
              );
            }}
          >
            {({ setFieldValue, errors, isSubmitting, isValid }) => (
              <>
                <Form>
                  {/* {FormikForm.renderTextArea("name", "eNTER MESSAGE", "text")} */}
                  <Field
                    className="form-control webkit-user-select"
                    as="textarea"
                    rows={10}
                    id={"message"}
                    name={"message"}
                    placeholder="您填写信息越全，问题越可有效解决哟~"
                    style={{
                      backgroundColor: "#fafafa",
                      borderRadius: "30px",
                      padding: "20px",
                      //   boxShadow: "3px 4px #888888",
                      marginBottom: "0.6em",
                      color: "#555353",
                    }}
                    onChange={(e) => {
                      setFieldValue("message", e.target.value);
                      setCharacterCount(e.target.value.length);
                    }}
                  />
                  <div id="the-count">
                    <span id="current" style={{ color: "#555353" }}>
                      {characterCount}
                    </span>
                    <span id="maximum" style={{ color: "#555353" }}>
                      {" "}
                      / 300
                    </span>
                  </div>
                  <ErrorMessage
                    className="text-danger text-right no-padding error-message"
                    name={"message"}
                    component="div"
                  />

                  {/* <Input
                    name={"phone_number"}
                    label={"phone number"}
                    type={"text"}
                    value="+60102004225"
                    style={{ margin: 0, marginTop: "2em" }}
                    disabled
                  /> */}

                  <Field
                    className="form-control"
                    name={"phone_number"}
                    type={"text"}
                    autoComplete="off"
                    value={`手机号 : ${phoneNumber}`}
                    style={{
                      backgroundColor: "#fafafa",
                      borderRadius: "40px",
                      height: "50px",
                      marginTop: "2em",
                      marginBottom: "10em",
                      paddingLeft: "1.4em",
                      color: "#c1c1c1",
                    }}
                    disabled
                  />
                  {/* <div
                    style={{
                      color: "#6B98C0",
                      marginTop: "1em",
                      fontSize: "0.9rem",
                    }}
                  ></div> */}
                  {FormikForm.renderButton("提交反馈", isSubmitting, isValid)}
                </Form>
              </>
            )}
          </Formik>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Suggestion;
