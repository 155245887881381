import React from "react"
import { Card } from "react-bootstrap"
import { EmptyImage } from "./../../assets/images/index"

const Room = ({ title, url }) => {
  return (
    <Card style={{ borderRadius: 15, marginTop: 15 }}>
      <Card.Img
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          height: 100,
        }}
        variant="top"
        src={url || EmptyImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = EmptyImage
        }}
      />
      <Card.Body style={{ height: "auto" }}>
        <Card.Text className="small-font" style={{ textAlign: "center" }}>
          {title}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Room
