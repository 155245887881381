import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Tabs, Button } from "react-bootstrap";
import renderServices from "../../services/render.services";
import bidServices from "../../services/bid.services";
import CountDownCustom from "./../common/countdown.custom";
import Loader from "./../common/loading/loader";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import common from "../../utils/common";
import Pin from "./../common/pin";

const FirstAuction = () => {
  const [loading, setLoading] = useState(false);
  const [bid_id, setBidId] = useState("");
  const [bidData, setBidData] = useState([]);
  const [currentTab, setCurrentTab] = useState("transferred");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({
    pin: false,
    name: "付款",
    // api: "/withdrawal",
    // link: "/withdrawal",
    bid_id: "",
    method: "post",
    type: "bid_payment",
  });

  const navigate = useNavigate();

  const getBidData = async (aucTitle) => {
    const response = await renderServices.bidHistoryList("transfer", aucTitle);
    setBidData(response.data);
    setLoading(false);
  };

  const redirect = (id) => {
    navigate("/bidding/process", { state: { bid_id: id } });
  };

  const handleBidPayment = () => {
    bidPayment(bid_id);
  };

  const closePin = () => {
    setData({
      pin: false,
    });
  };

  function renderBidHistoryBtmDetail(bidData) {
    switch (bidData.bid_status_translation) {
      case "已预约":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">起拍价：</div>
              </Col>
              <Col className="auction_info_col">
                <div
                  className="auction_info_wording_red auction_wording_style1"
                  style={{ fontSize: "15px" }}
                >
                  ￥{bidData.start_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">距离开始：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  <CountDownCustom date={bidData.start_time}></CountDownCustom>
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">参与人数：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.participant_number}
                </div>
              </Col>
            </Row>
          </>
        );
      case "预拍":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">起拍价：</div>
              </Col>
              <Col className="auction_info_col">
                <div
                  className="auction_info_wording_red auction_wording_style1"
                  style={{ fontSize: "15px" }}
                >
                  ￥{bidData.start_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">距离开始：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  <CountDownCustom date={bidData.start_time}></CountDownCustom>
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">参与人数：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.participant_number}
                </div>
              </Col>
            </Row>
          </>
        );
      case "竞拍中":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">起拍价：</div>
              </Col>
              <Col className="auction_info_col">
                <div
                  className="auction_info_wording_red auction_wording_style1"
                  style={{ fontSize: "15px" }}
                >
                  ￥{bidData.start_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">距离结束：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  <CountDownCustom date={bidData.end_time}></CountDownCustom>
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">参与人数：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.participant_number}
                </div>
              </Col>
            </Row>
          </>
        );
      case "已支付":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">结束时间：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  {bidData.ended_time}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.reserve_price}
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">成交价：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.deal_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">剩余付款：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.balance}
                </div>
              </Col>
            </Row>
            {bidData.payment_end_time && (
              <Row>
                <Button
                  variant="danger"
                  className="bid-history-btn"
                  onClick={() => {
                    // bidPayment(bidData.id)
                    setBidId(bidData.id);
                    setData({
                      pin: true,
                      name: "付款",
                      // api: "/withdrawal",
                      // link: "/withdrawal",
                      bid_id: "",
                      method: "post",
                      type: "bid_payment",
                    });
                  }}
                >
                  付款&nbsp;&nbsp;
                  <CountDownCustom
                    date={bidData.payment_end_time}
                  ></CountDownCustom>
                </Button>{" "}
              </Row>
            )}
            {bidData.transfer_end_time && (
              <Row>
                <Button
                  variant="danger"
                  className="bid-history-btn"
                  onClick={() => {
                    bidTransfer(bidData.id);
                  }}
                >
                  开始转拍&nbsp;&nbsp;
                  <CountDownCustom
                    date={bidData.transfer_end_time}
                  ></CountDownCustom>
                </Button>{" "}
              </Row>
            )}
          </>
        );
      case "竞拍失败":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">结束时间：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_red auction_wording_style1">
                  {bidData.ended_time}
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">成交价：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  ￥{bidData.deal_price}
                </div>
              </Col>
            </Row>
          </>
        );
      case "违约":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">结束时间：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  {bidData.ended_time}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey"></div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {/* ￥{bidData.deal_price} */}
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">成交价：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  ￥{bidData.deal_price}
                </div>
              </Col>
            </Row>
          </>
        );
      case "进行中":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">起拍价：</div>
              </Col>
              <Col className="auction_info_col">
                <div
                  className="auction_info_wording_red auction_wording_style1"
                  style={{ fontSize: "15px" }}
                >
                  {bidData.start_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">距离结束：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  <CountDownCustom date={bidData.end_time}></CountDownCustom>
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">参与人数：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.participant_number}
                </div>
              </Col>
            </Row>
          </>
        );
      case "成功":
        return (
          <>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">成交价:</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_red auction_wording_style1">
                  {bidData.deal_price}
                </div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">结束时间</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style2">
                  {bidData.ended_time}
                </div>
              </Col>
            </Row>
            <Row className="auction_info_row">
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey">保证金：</div>
              </Col>
              <Col className="auction_info_col">
                <div className="auction_info_wording_grey auction_wording_style1">
                  ￥{bidData.reserve_price}
                </div>
              </Col>
            </Row>
          </>
        );
    }
    return (
      <>
        <Row className="auction_info_row">
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey">起拍价：</div>
          </Col>
          <Col className="auction_info_col">
            <div
              className="auction_info_wording_red auction_wording_style1"
              style={{ fontSize: "15px" }}
            >
              ￥{bidData.start_price}
            </div>
          </Col>
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey">距离结束：</div>
          </Col>
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey auction_wording_style2">
              <CountDownCustom date={bidData.end_time}></CountDownCustom>
            </div>
          </Col>
        </Row>
        <Row className="auction_info_row">
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey">保证金：</div>
          </Col>
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey auction_wording_style1">
              ￥{bidData.reserve_price}
            </div>
          </Col>
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey">参与人数：</div>
          </Col>
          <Col className="auction_info_col">
            <div className="auction_info_wording_grey auction_wording_style2">
              {bidData.participant_number}
            </div>
          </Col>
        </Row>
        {bidData.payment_end_time && (
          <Row>
            <Button
              variant="danger"
              className="bid-history-btn"
              onClick={() => {
                // bidPayment(bidData.id)
                setBidId(bidData.id);
                setData({
                  pin: true,
                  name: "付款",
                  // api: "/withdrawal",
                  // link: "/withdrawal",
                  bid_id: "",
                  method: "post",
                  type: "bid_payment",
                });
              }}
            >
              付款&nbsp;&nbsp;
              <CountDownCustom
                date={bidData.payment_end_time}
              ></CountDownCustom>
            </Button>{" "}
          </Row>
        )}
        {bidData.transfer_end_time && (
          <Row>
            <Button
              variant="danger"
              className="bid-history-btn"
              onClick={() => {
                bidTransfer(bidData.id);
              }}
            >
              开始转拍&nbsp;&nbsp;
              <CountDownCustom
                date={bidData.transfer_end_time}
              ></CountDownCustom>
            </Button>{" "}
          </Row>
        )}
      </>
    );
  }
  const bidTransfer = async (bid_id) => {
    try {
      const response = await bidServices.bidTransfer(bid_id);

      if (response) {
        common.sweetAlertCustom({
          title: "通知",
          html: response.message,
          showCancelButton: false,
          showConfirmButton: false,
        });
        setCurrentTab("complete");
        setLoading(true);
        setBidData([]);
        getBidData("complete");
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const errors = ex.response.data.errors;

        Object.keys(errors).map((item, i) => {
          common.sweetAlertCustom({
            title: "通知",
            html: `${errors[item]}`,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });
      }
    }
  };
  const bidPayment = async (bid_id) => {
    try {
      const response = await bidServices.bidPayment(bid_id);

      if (response) {
        common.sweetAlertCustom({
          title: "通知",
          html: response.message,
          showCancelButton: false,
          showConfirmButton: false,
        });
        setData({ pin: false });
        setCurrentTab("complete");
        setLoading(true);
        setBidData([]);
        getBidData("complete");
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const errors = ex.response.data.errors;

        Object.keys(errors).map((item, i) => {
          common.sweetAlertCustom({
            title: "通知",
            html: `${errors[item]}`,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });
      }
    }
  };
  function showListing(currentTab) {
    return (
      <>
        {!loading && bidData.length > 0 ? (
          bidData.map((bidData, i) => {
            var statusBtnBgColor = "#0057d9";
            if (bidData.bid_status_translation === "已预约") {
              statusBtnBgColor = "#F2C161";
            } else if (
              ["竞拍中", "已支付", "成功"].includes(
                bidData.bid_status_translation
              )
            ) {
              statusBtnBgColor = "#3B824F";
            } else if (
              ["竞拍失败", "违约"].includes(bidData.bid_status_translation)
            ) {
              statusBtnBgColor = "#EC0000";
            } else if (bidData.bid_status_translation === "进行中") {
              statusBtnBgColor = "#F26E0C";
            } else if (bidData.bid_status_translation === "预拍") {
              statusBtnBgColor = "#0057D9";
            }
            return (
              <div
                onClick={() => {
                  if (
                    _.includes(
                      ["waiting for bid", "transferred", "bidding"],
                      currentTab
                    )
                  )
                    redirect(bidData.id);
                }}
                className="auction_listingRow"
                key={i}
              >
                <Row>
                  <Col className="col-4">
                    <img
                      src={`${bidData.image}`}
                      className="auction_listing_pic"
                      alt=""
                    />
                  </Col>
                  <Col className="auction_pic_col">
                    <Row>
                      <Col>
                        <div className="auction_listing_vipLv">
                          VIP {bidData.min_vip}
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="auction_listing_status"
                          style={{ backgroundColor: statusBtnBgColor }}
                        >
                          {bidData.bid_status_translation}
                        </div>
                      </Col>
                      <Col className="auction_roomId_col">
                        <div className="auction_listing_roomId">
                          ID: {bidData.code}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div
                        className="auction_listing_description"
                        style={{ fontSize: "15px" }}
                      >
                        {bidData.name}
                      </div>
                    </Row>
                  </Col>
                </Row>
                {renderBidHistoryBtmDetail(bidData)}
              </div>
            );
          })
        ) : loading ? (
          <Loader style={{ backgroundColor: "white", height: "80vh" }} />
        ) : (
          <div className="auction_body_default">目前没有记录</div>
          // <div className="auction_body_default"> 目前没有记录</div>
        )}
      </>
    );
  }

  useEffect(() => {
    localStorage.setItem("bid_history_type", "/auction/first");

    getBidData("transferred").catch((e) => {});
    if (searchParams.get("status") === "complete") {
      setCurrentTab("complete");
      setLoading(true);
      setBidData([]);
      getBidData("complete");
    } else if (searchParams.get("status") === "fail") {
      setCurrentTab("fail");
      setLoading(true);
      setBidData([]);
      getBidData("fail");
    }
  }, []);

  return (
    <React.Fragment>
      <Pin data={data} customFunction={handleBidPayment} closePin={closePin} />
      <div className="first-auction-body">
        <Tabs
          id="firstAuctionTitle"
          defaultActiveKey="transferred"
          activeKey={currentTab}
          transition={false}
          onSelect={(aucTitle) => {
            setCurrentTab(aucTitle);
            setLoading(true);
            setBidData([]);
            getBidData(aucTitle);
          }}
        >
          <Tab eventKey="transferred" title="已转拍">
            {showListing(currentTab)}
          </Tab>
          <Tab eventKey="waiting for bid" title="已预约">
            {showListing(currentTab)}
          </Tab>
          <Tab eventKey="bidding" title="竞拍中">
            {showListing(currentTab)}
          </Tab>
          <Tab eventKey="waiting for payment" title="待付款">
            {showListing(currentTab)}
          </Tab>
          <Tab eventKey="complete" title="已完成">
            {showListing(currentTab)}
          </Tab>
          <Tab eventKey="fail" title="流拍">
            {showListing(currentTab)}
          </Tab>
        </Tabs>
      </div>
    </React.Fragment>
  );
};

export default FirstAuction;
