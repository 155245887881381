import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import renderServices from "../../services/render.services";
import { toast } from "react-toastify";
import Text from "./../common/text";
import Merchant from "./../common/merchant";
import Loader from "./../common/loading/loader";
import { Carousel } from "react-responsive-carousel";
function convert(text) {
  if (text) {
    return text.replace(/\\r\\n/g, "<br>");
  } else {
    return <div></div>;
  }
}
const ProductDetail = () => {
  const [merchant, setMerchant] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const [detail, setDetail] = useState({
    original_price: "-",
    estimated_price: "-",
    sub_image: [],
  });

  const getProductDetail = async (id) => {
    if (id) {
      const response = await renderServices.getProduct(id);
      response.data.description = convert(response.data.description);
      setDetail(response.data);
      setMerchant(response.data.merchant);
      setLoading(false);
    } else {
      toast.error(`产品ID错误`);
    }
  };

  useEffect(() => {
    setLoading(true);
    getProductDetail(id).catch((e) => {
      console.log(e);
    });
  }, []);
  // console.log("datail ", detail);

  if (!loading) {
    return (
      <React.Fragment>
        <Container className="g-0" style={{ overflowX: "hidden" }}>
          <Row
            className="product-detail-image-row g-0"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col>
              <Carousel
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                autoPlay
                className="carousel_product_detail"
                showArrows={false}
                style={{ height: "300px" }}
              >
                {detail.sub_image.map((item) => {
                  return (
                    <img
                      alt=""
                      src={item}
                      className="carousel_product_detail"
                    />
                  );
                })}
              </Carousel>
              {/* <img
                className="product-detail-image"
                src={detail.mainMediaUrl}
                alt=""
              /> */}
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <h2
                className="text-left medium-font darkgrey-font"
                style={{ color: "black" }}
              >
                {detail.name}
              </h2>
            </Col>
          </Row>
          <Row className="g-0">
            <Col xs={4}>
              <Row className="g-0">
                <Text className="text-right x-small-font" value="RMB"></Text>
              </Row>
              <Row className="g-0">
                <Text
                  className="text-right x-small-font red-font"
                  value="官方售价"
                  style={{ padding: "0" }}
                ></Text>
              </Row>
            </Col>
            <Col xs={8}>
              <Row className="g-0">
                <Text
                  className="xx-large-font align-center"
                  value={detail.original_price}
                  style={{ color: "#FF4F28", fontWeight: "bold" }}
                ></Text>
              </Row>
            </Col>
          </Row>

          <Row style={{ padding: 10 }} className="g-0">
            <Col className="col-2">
              <Row className="g-0">
                <Text
                  className="x-small-font mediumgrey-font"
                  value="官方售价："
                  style={{ padding: "0" }}
                ></Text>
              </Row>
              <Row className="g-0">
                <Text
                  className="x-small-font mediumgrey-font"
                  value="专家估价："
                  style={{ padding: "0" }}
                ></Text>
              </Row>
            </Col>
            <Col className="col-4">
              <Row className="g-0">
                <Text
                  className="text-left x-small-font mediumgrey-font"
                  value={"￥" + detail.original_price}
                  style={{ padding: "0" }}
                ></Text>
              </Row>
              <Row className="g-0">
                {" "}
                <Text
                  className="text-left x-small-font mediumgrey-font"
                  value={"￥" + detail.estimated_price}
                  style={{ padding: "0" }}
                />
              </Row>
            </Col>
            <Col className="col-2">
              <Row className="g-0">
                <Text
                  className="text-right x-small-font mediumgrey-font"
                  value="存库："
                  style={{ padding: "0" }}
                />
              </Row>
            </Col>
            <Col className="col-4">
              <Row className="g-0">
                <Text
                  className="text-left x-small-font mediumgrey-font"
                  value={detail.stock}
                  style={{ padding: "0" }}
                />
              </Row>
            </Col>
          </Row>

          <Row className="g-0">
            <Row className="g-0">
              <Text className="text-left small-font" value="商品描述：" />
            </Row>
            <Row className="g-0">
              {/* <Text
                className="text-left small-font grey-font"
                value={detail.description}
              /> */}
              <div
                className="text-left small-font grey-font"
                contentEditable="false"
                style={{ color: "darkslategrey" }}
                dangerouslySetInnerHTML={{
                  __html: detail.description,
                }}
              ></div>
            </Row>
          </Row>
          {Object.keys(merchant).length > 0 ? (
            <Merchant merchant={merchant} slug={detail.slug} />
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  } else {
    return <Loader style={{ backgroundColor: "white", height: "80vh" }} />;
  }
};

export default ProductDetail;
