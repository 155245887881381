import React from "react";
import { Container, Row } from "react-bootstrap";
import { VIPOne, VIPTwo, VIPThree } from "../../assets/images";
import Text from "./../common/text";

const VIP = () => {
  return (
    <Container className="container-full-white">
      <Row className="tnc-row" style={{ textAlign: "center" }}>
        <Text value="转拍VIP" style={{ fontSize: "larger" }} />
      </Row>
      <Row className="tnc-row">
        <Text value="1. 免费转拍次数用完后，即可向客服申请购买无限转拍 VIP。" />
      </Row>
      <Row className="tnc-row" style={{ textAlign: "center" }}>
        <Text value="VIP 特权" style={{ fontSize: "larger" }} />
      </Row>
      <Row className="tnc-row">
        <Text value="1. 客户提现免除服务费" />
      </Row>
      <Row className="tnc-row">
        <Text value="2. 平台 VIP 福利赠送折扣券,优惠券,现金红包" />
      </Row>
      <Row className="tnc-row">
        <Text value="3. 京东VIP活动邀请" />
      </Row>
      <Row className="tnc-row">
        <Text value="4. 平台第一手消息和优惠" />
      </Row>
      <Row className="tnc-row">
        <Text value="5. 高级场次参与权(上市后)" />
      </Row>
      <Row className="tnc-row">
        <Text value="6. 24小时客服服务(上市后)" />
      </Row>
      <Row className="tnc-row">
        <Text value="平台福利乃VIP会员专属享有,用户领取福利后需向客服申请成为平台VIP会员方可进行提现" />
      </Row>
      <Row className="tnc-row" style={{ textAlign: "center" }}>
        <Text value="本活动公司保有最终解释权" style={{ fontSize: "larger" }} />
      </Row>

      <Row className=""></Row>
      <Row className=""></Row>
      <Row className=""></Row>
      <Row className=""></Row>
      <Row className=""></Row>
      <Row className=""></Row>
      <Row className="vip-row grey-font"></Row>
    </Container>
    // <Container className="container-full-white">
    //   <Row>
    //     <img src={VIPOne} alt="" />
    //   </Row>
    //   <Row className="vip-row grey-font">
    //     1. 免费转拍次数用完后，即可向客服申请购买无限转 拍 VIP。
    //   </Row>
    //   <Row>
    //     <img src={VIPTwo} alt="" />
    //   </Row>
    //   <Row className="vip-row grey-font">1. 客户提现免除服务费</Row>
    //   <Row className="vip-row grey-font">
    //     2.平台 VIP 福利赠送折扣券,优惠券,现金红包
    //   </Row>
    //   <Row className="vip-row grey-font">3.京东VIP活动邀请</Row>
    //   <Row className="vip-row grey-font">4. 平台第一手消息和优惠</Row>
    //   <Row className="vip-row grey-font">55. 高级场次参与权(上市后)</Row>
    //   <Row className="vip-row grey-font">•6.24小时客服服务(上市后)</Row>
    //   <Row className="vip-row grey-font">
    //     平台福利乃VIP会员专属享有,用户领取福利后需向
    //   </Row>
    //   <Row className="vip-row grey-font">
    //     客服申请成为平台VIP会员方可进行提现!
    //   </Row>
    //   <Row>
    //     <img src={VIPThree} alt="" />
    //   </Row>
    // </Container>
  );
};

export default VIP;
