import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import renderServices from "../../services/render.services";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { Field, ErrorMessage } from "formik"
import { useNavigate } from "react-router";

import FormikForm from "../common/formik.form";
import * as Yup from "yup";
import Pin from "./../common/pin";
import _ from "lodash";

const Withdrawal = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    amount: "",
    pin: false,
    estimatedReceivedAmount: "0",
  });

  const closePin = () => {
    setData({
      pin: false,
      estimatedReceivedAmount: "0",
    });
  };

  async function onSubmit(data, setFieldError) {
    if (_.isEmpty(localStorage.getItem("trade_pin"))) {
      navigate("/set_pin");
    }

    try {
      setData({
        pin: true,
        name: "申请提现",
        api: "/withdrawal",
        link: "/withdrawal",
        amount: data.amount,
        method: "post",
        type: "withdrawal",
      });
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      }
    }
  }

  const [accNo, setAccNo] = useState("-");
  const [balance, setBalance] = useState(0);
  const [amount] = useState("");
  const [paymentName, setPaymentName] = useState("");

  const getAccNo = async () => {
    const response = await renderServices.userInfo();
    setAccNo(response.data.account_number);
  };

  const getBalance = async () => {
    const response = await renderServices.userBalance();
    setBalance(response.data.wallet_balance);
  };

  function calculation(amount) {
    let withdrawal_fee = amount * 0.01;
    let estimatedReceivedAmount = amount - withdrawal_fee;
    setData({ estimatedReceivedAmount: estimatedReceivedAmount.toFixed(2) });
  }
  const getUser = async () => {
    const response = await renderServices.userInfo();
    setPaymentName(response.data.payment_name);
  };
  useEffect(() => {
    getAccNo().catch((e) => {
      console.log(e);
    });
    getBalance().catch((e) => {
      console.log(e);
    });
    getUser().catch((e) => {
      console.log(e);
    });
  }, []);

  const WithdrawalSchema = Yup.object({
    amount: Yup.number()
      .moreThan(0, "提现金额必须超过0")
      .typeError("提现金额格式必须是数字")
      .required("请输入提现金额"),
  });

  return (
    <React.Fragment>
      <Container
        className="container withdrawal"
        style={{ marginTop: "2em", height: "100vh" }}
      >
        <Pin data={data} closePin={closePin} />
        <Formik
          initialValues={{ amount: amount }}
          validationSchema={WithdrawalSchema}
          onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
            onSubmit(values, setFieldError, navigate);
            resetForm({ amount: "" });
            setSubmitting(false);
          }}
        >
          {({ handleChange, setFieldValue, errors, isSubmitting, isValid }) => (
            <Form>
              <Row
                className="topup-row-grey topup-row row"
                style={{ margin: "0em 2em", padding: "0em 0em" }}
              >
                <Col className="col-5" style={{ padding: 0 }}>
                  <Field
                    className="withdrawal_row2_amount"
                    placeholder="请输入提现金额"
                    name="amount"
                    type="number"
                    onChange={(e) => {
                      handleChange(e);
                      let someValue = e.currentTarget.value;
                      calculation(someValue);
                    }}
                  ></Field>
                </Col>
                <Col className="col-7">
                  <div className="withdrawal_row2_currency">RMB</div>
                </Col>
              </Row>
              <ErrorMessage
                className="text-danger text-left error-message"
                name="amount"
                component="div"
                style={{ marginLeft: "2.5em" }}
              />
              <Row className="topup-row-grey row">
                <Col className="col-6">
                  <div className="withdrawal_row3_name">可提现金额：</div>
                </Col>
                <Col>
                  <div className="withdrawal_row3_balance">￥ {balance}</div>
                </Col>
              </Row>
              <Row className="topup-row-grey row">
                <Col className="col-6">
                  <div className="withdrawal_row4_name">提现银行名字：</div>
                </Col>
                <Col className="col-6">
                  <div className="withdrawal_row4_balance">{paymentName}</div>
                </Col>
              </Row>
              <Row className="topup-row-grey row">
                <Col className="col-6">
                  <div className="withdrawal_row4_name">提现到：</div>
                </Col>
                <Col className="col-6">
                  <div className="withdrawal_row4_balance">{accNo}</div>
                </Col>
              </Row>
              <Row className="topup-row-grey row">
                <Col className="col-6">
                  <div className="withdrawal_row5_name">到账周期：</div>
                </Col>
                <Col className="col-6">
                  <div className="withdrawal_row5_balance">24小时内</div>
                </Col>
              </Row>
              <Row className="topup-row-grey row">
                <Col className="col-6">
                  <div
                    className="withdrawal_row6_name"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    实际到账余额：
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="withdrawal_row6_balance">
                    {data.estimatedReceivedAmount}
                  </div>
                </Col>
              </Row>
              <Row className="topup-row-grey row" style={{ marginLeft: "3em" }}>
                {FormikForm.renderButton(
                  "立即提现",
                  isSubmitting,
                  isValid,
                  "special-case"
                )}
                {/* <Row className="custom-button">
                  <button type="submit" className="btn" disabled={isSubmitting}>
                    {isSubmitting ? "Please wait..." : "立即提现"}
                  </button>
                </Row> */}

                {/* <button type="submit" className="withdrawal_button">
                  {isSubmitting ? "Please wait..." : "立即提现"}
                </button> */}
              </Row>
              {/* {FormikForm.renderButton("立即注册", isSubmitting, isValid)} */}
            </Form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};
export default Withdrawal;
