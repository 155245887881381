import React from "react"

const Text = ({ value, className, style }) => {
  return (
    <label className={className} style={style}>
      {value}
    </label>
  )
}

export default Text
