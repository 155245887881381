// React Base Library //
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Iframe from "react-iframe";
import _, { min } from "lodash";
// Custom //
import FormikForm from "../common/formik.form";
// Assets //
import logo from "../../assets/images/logo.png";
// Services //
import authServices from "../../services/auth.services";
import userServices from "../../services/user.services";
// Utils //
import common from "../../utils/common";
import { LiveChatWidget, useWidgetState } from "@livechat/widget-react";
import {
  parsePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";

async function onSubmit(
  data,
  setFieldError,
  navigate,
  setSubmitting,
  newPassword
) {
  try {
    const response = await authServices.login(data.username, data.password);
    if (response) {
      const profileRes = await userServices.getProfile();
      const profile = profileRes.data || "";
      localStorage.setItem("username", data.username);
      localStorage.setItem("trade_pin", profile.data.trade_pin || "");
      common.sweetAlertCustom({
        title: "通知",
        html: `登入成功`,
        showCancelButton: false,
        showConfirmButton: false,
      });
      navigate("/dashboard", { replace: true });
    }
  } catch (ex) {
    if (ex && Object.keys(ex).length > 0) {
      // const error = ex.response.data
      if (ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // console.log("errors ", errors)
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            // alert(item + " " + errors[item]);
            setFieldError(item, errors[item]);
          });
        }
      }
      // setFieldError("password", error.message)
    }
  }
  setSubmitting(false);
}
window.addEventListener("load", handleLoad);
function handleLoad() {
  // console.log("loaded ...");
}
const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
    showPassword: false,
  });
  const [isComposition, setIsComposition] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hide, setHide] = useState("hide");
  const [visibility, setVisibility] = useState("minimized");
  const widgetState = useWidgetState();
  const schema = Yup.object({
    // username: Yup.string()
    //   .typeError("手机号码格式必须是数字")
    //   .required("需要填入手机号码")
    //   .min(12, "填入您的手机号码"),
    username: Yup.string()
      .typeError("手机号码格式必须是数字")
      .required("需要填入手机号码")
      .test("username", "填入您的手机号码", (value) => {
        if (value != "" && value != undefined) {
          const phoneNumber = parsePhoneNumber(value);
          const AUphoneNum = parsePhoneNumber(formatPhoneNumberIntl(value));

          if (AUphoneNum !== "" && AUphoneNum !== undefined) {
            if (AUphoneNum.countryCallingCode == "61") {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
          }

          if (phoneNumber) {
            if (phoneNumber.country === "US" || phoneNumber.country === "CA") {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "HK" || phoneNumber.country === "MO") {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "SG") {
              if (value.length >= 11) {
                return true;
              } else {
                return false;
              }
            }
            if (
              phoneNumber.country === "MY" ||
              phoneNumber.country === "AU" ||
              phoneNumber.country === "TH"
            ) {
              if (value.length >= 12) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "TW") {
              if (value.length >= 13) {
                return true;
              } else {
                return false;
              }
            }
            if (phoneNumber.country === "CN") {
              if (value.length >= 14) {
                return true;
              } else {
                return false;
              }
            }
          }
        }
      }),
    password: Yup.string()
      .required("需要填入密码")
      .matches(
        /^[ A-Za-z0-9_@~`! @#$%^&*()_=+\\\\';:\"\\/?>. <,-]*$/,
        "密码格式错误"
      ),
  });
  const handleClickShowPassword = () => {
    setData({ ...data, showPassword: !data.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };
  const [testPw, setTestPw] = useState("");
  setInterval(() => {
    setLoading(false);
  }, 1000);
  useEffect(() => {
    // console.log("use effecsssssst");
    common.authNavigate(navigate);
  });

  const handleChange = (setFieldValue, ev) => {
    if (!isComposition) {
      setFieldValue(ev.target.value);
    }
  };
  const handleComposition = (ev) => {
    if (ev.type === "compositionend") {
      setIsComposition(false);
      if (isComposition) {
        // setFieldValue(ev.target.value);
      }
    } else {
      setIsComposition(true);
    }
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }
  return (
    <React.Fragment>
      <Container className="container-full-white">
        <Row className="text-right large-font g-0">
          <NavLink className="black-font" to="/register">
            注册
          </NavLink>
        </Row>
        <Row className="logo g-0">
          <img src={logo} alt="logo" className="logo-img" />
        </Row>
        <Row className="logo-label g-0">
          <label>欢迎使用京东拍卖商城</label>
        </Row>
        <Row className="login-form g-0">
          <Formik
            initialValues={data}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              onSubmit(values, setFieldError, navigate, setSubmitting, testPw);
            }}
          >
            {({ setFieldValue, errors, isSubmitting, isValid, values }) => (
              <Form autoComplete="off">
                {FormikForm.renderMobileInput(
                  "username",
                  "请输入您的手机号码",
                  setFieldValue,
                  errors
                )}
                {FormikForm.renderInput(
                  "password",
                  "请输入密码",
                  "text",
                  "new-password",
                  "key",
                  true
                )}
                {/* <Row className="custom-field">
                  <Input
                    type={data.showPassword ? "text" : "text"}
                    style={{
                      height: "40px",
                      border: "0px !important",
                      background: "#f9f9f9",
                      borderRadius: "2em",
                    }}
                    className="key"
                    onChange={(e) => {
                      const value = e.target.value;
                      console.log("onchg ", value);
                      const regex = /^[*0-9a-zA-Z(\-)]+$/; //this will admit letters, numbers and dashes
                      // if (value.match(regex) || value === "") {
                      let current_value = values.password;
                      let new_value = e.target.value;
                      if (current_value.length < new_value.length) {
                        // adding new str
                        var x = "";
                        if (e.target.value.indexOf("*") > -1) {
                          // x = testPw.concat(replaceAll(e.target.value, "*", ""));
                          x = testPw.concat(e.target.value.slice(-1));
                        } else {
                          x = e.target.value;
                        }
                        console.log("x is ", x);
                        setFieldValue("password", x);
                        setTestPw(x);
                      } else {
                        // delete button
                        setTestPw("");
                        setFieldValue("password", "");
                      }
                    }}
                    // onChange={(e) => {
                    //   setFieldValue("password", e.target.value);
                    //   setTestPw()
                    // }}
                    value={
                      !data.showPassword
                        ? "*".repeat(values.password.length)
                        : testPw
                    }
                    autoComplete={"new-password"}
                    placeholder="请输入密码"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {data.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <ErrorMessage
                    className="text-danger text-right no-padding error-message"
                    name={"password"}
                    component="div"
                  />
                </Row> */}
                {FormikForm.renderButton("登入", isSubmitting, isValid)}
                <Row className="g-0">
                  <Col className="text-left">
                    <span
                      onClick={() => {
                        window.open("https://direct.lc.chat/14022327/");
                        // setHide("");
                        // setVisibility("maximized");
                      }}
                      className="mediumgrey-font"
                    >
                      联系客服
                    </span>
                  </Col>
                  <Col className="text-right">
                    <span
                      onClick={() => {
                        window.open(" https://direct.lc.chat/14022327/");
                        // setHide("")

                        // common.sweetAlertCustom({
                        //   title: "通知",
                        //   html: "此服务展示未能开通，请联系客服",
                        //   showCancelButton: false,
                        //   showConfirmButton: false,
                        // })
                        // setHide("");
                        // setStartCount(1);

                        // setVisibility("maximized");
                        // return (
                        //   <LiveChatWidget
                        //     license="14022327"
                        //     visibility="maximized"
                        //     onNewEvent={() => {
                        //       setHide("hide");
                        //     }}
                        //   />
                        // );
                      }}
                      className="mediumgrey-font"
                    >
                      忘记密码
                    </span>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Row>
        {/* {test()} */}
        {/* <LiveChatWidget
          license="14022327"
          visibility={visibility}
          // visibility="minimized"
          // onNewEvent={() => {
          //   console.log("new");
          //   setVisibility("hidden");
          // }}
          onVisibilityChanged={() => {
            // if (startCount != 0) {
            //   console.log("it changed");
            //   setVisibility("minimized");
            // }
            if (widgetState.visibility === "maximized") {
              setVisibility("minimized");
            }
          }}
        /> */}
        {/* {visibility && (
          <LiveChatWidget
            license="14022327"
            visibility={visibility}
            onNewEvent={() => {
              console.log("new");
              // setVisibility("hidden");
            }}
            onVisibilityChanged={() => {
              console.log("it changed");
              setVisibility("minimized");
            }}
          />
        )} */}

        {/* {_.isEqual(hide, "") ? (
          <LiveChatWidget license="14022327" visibility="maximized" />
        ) : (
          ""
        )} */}
        {/* {_.isEqual(hide, "") ? (
          <div>
            <Iframe
              url="https://direct.lc.chat/14022327/"
              width="100%"
              height="100%"
              id="myId"
              className={`iframe-custom ${hide}`}
              position="fixed"
              overflow="hidden"
            ></Iframe>
            <Button
              className={`iframe-close-button ${hide}`}
              onClick={() => {
                setHide("hide")
              }}
            >
              <FontAwesomeIcon value="10" icon={faX} />
            </Button>
          </div>
        ) : (
          ""
        )} */}
      </Container>
    </React.Fragment>
  );
};

export default Login;
