import React from "react";
import Countdown from "react-countdown";
import Moment from "moment";

const Completionist = ({ completeText = "已经完毕" }) => {
  // if (completeText != null && completeText != undefined) {
  //   return <span>已经完毕</span>;
  // } else {
  return <span>{completeText}</span>;
  // }
};

const renderer = ({ days, hours, minutes, seconds, completed, props }) => {
  if (completed) {
    // Render a completed state
    return <Completionist completeText={props.completeText} />;
  } else {
    // Render a countdown
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        {days}天 {hours}:{minutes}:{seconds}
      </span>
    );
  }
};
const CountDownCustom = ({ date, action, completeText }) => {
  if (date) {
    let dateCast = new Date(date.replace(/-/g, "/"));
    return (
      <Countdown
        date={dateCast}
        renderer={renderer}
        onComplete={() => {
          if (action) {
            action();
          }
        }}
        completeText={completeText}
      />
    );
  } else {
    return "";
  }
};

export default CountDownCustom;
