import React, { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import { Container, Row, Col } from "react-bootstrap"
import Ticker from "react-ticker"
import {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  Banner6,
  Banner7,
  Banner8,
  Banner9,
  Banner10,
  Banner11,
  Banner12,
} from "./../../assets/images/index"
import Navigation from "../common/navbar"
import { useNavigate, NavLink } from "react-router-dom"
import { toast } from "react-toastify"
import uuid from "react-uuid"
import common from "../../utils/common"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import {
  ButtonBGOne,
  ButtonBGTwo,
  ButtonBGThree,
  ButtonBGFour,
  DashIcon,
} from "./../../assets/images/index"
import renderServices from "../../services/render.services"
const AnnouncementBox = () => {
  const [announcement, setAnnouncement] = useState("")
  useEffect(() => {
    async function fetchData() {
      const ratesFromAPI = await renderServices.getAnnouncement()
      setAnnouncement(ratesFromAPI.data)
    }
    fetchData()
  }, [])
  // A placeholder is needed, to tell react-ticker, that width and height might have changed
  // It uses MutationObserver internally
  return announcement ? (
    <p style={{ whiteSpace: "nowrap", color: "#878585", fontSize: "0.9rem" }}>
      {announcement}{" "}
    </p>
  ) : (
    <p style={{ visibility: "hidden" }}>Placeholder</p>
  )
}
const Dashboard = () => {
  const navigate = useNavigate()
  const [dashboardNavState] = useState({ navState: "/dashboard" })

  const toastEvent = (title) => {
    common.sweetAlertCustom({
      title: "通知",
      html: title,
      showCancelButton: false,
      showConfirmButton: false,
    })
  }
  useEffect(() => {})
  return (
    <React.Fragment>
      <Navigation />
      <div
        className="main_noBorder2"
        style={{ paddingBottom: "200px", overflow: "hidden" }}
      >
        <Carousel
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          autoPlay
          className="dashboard_display"
          showArrows={false}
        >
          <img alt="" src={Banner8} className="dashboard_display" />
          <img alt="" src={Banner4} className="dashboard_display" />
          <img alt="" src={Banner5} className="dashboard_display" />
          <img alt="" src={Banner6} className="dashboard_display" />
          <img alt="" src={Banner7} className="dashboard_display" />
          <img alt="" src={Banner1} className="dashboard_display" />
          <img alt="" src={Banner9} className="dashboard_display" />
          <img alt="" src={Banner10} className="dashboard_display" />
          <img alt="" src={Banner11} className="dashboard_display" />
          <img alt="" src={Banner12} className="dashboard_display" />
          <img alt="" src={Banner2} className="dashboard_display" />
          <img alt="" src={Banner3} className="dashboard_display" />
        </Carousel>
        <div className="" style={{ height: "361px" }}>
          <div className="dash_annoucement">
            <Ticker height={30} offset="run-in">
              {() => <AnnouncementBox />}
            </Ticker>
            {/* <Ticker offset="run-in">
              {() => <AnnouncementBox content={announcement} />}
            </Ticker> */}
            <Container>
              <div
                className="dash_announcement_line"
                style={{ overflow: "unset" }}
              >
                <NavLink
                  to="/tnc"
                  className="non-decoration"
                  state={dashboardNavState}
                >
                  <Row className="g-0 dash_announcement_word">
                    <Col>
                      <span className="darkgrey-font">拍卖</span>
                      <span className="red-font">规则</span>
                      <img alt="" src={DashIcon} className="dash-icon" />
                    </Col>
                    <Col className="col-3">
                      <span href="/" className="mediumgrey-font">
                        马上阅读 {">"}
                      </span>
                    </Col>
                  </Row>
                </NavLink>
              </div>
            </Container>
          </div>
          <div className="dash_container">
            <Container style={{ overflowY: "unset" }}>
              <Row className="dash_row1">
                <Col
                  className="dash-redbox "
                  style={{
                    backgroundImage: `url(${ButtonBGOne})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right",
                  }}
                  onClick={() => {
                    // toastEvent("此服务展示未能开通，请联系客服")
                    navigate("/bidding/empty_room?name=新手体验", {
                      replace: true,
                      state: dashboardNavState,
                    })
                  }}
                >
                  <Col className="dash-layer">
                    <Col>
                      <span className="dash_white_nameFont">新手体验</span>
                      <br></br>
                      <span className="dash_white_linkFont" href="/">
                        点击查看 {">"}
                      </span>
                    </Col>
                    <Col className="rebBoxCol2"></Col>
                  </Col>
                </Col>
                <Col
                  className="dash-redbox"
                  style={{
                    backgroundImage: `url(${ButtonBGTwo})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right",
                  }}
                  onClick={() => {
                    // toastEvent("此服务展示未能开通，请联系客服");
                    navigate("/bidding/empty_room?name=0元起拍", {
                      replace: true,
                      state: dashboardNavState,
                    })
                  }}
                >
                  <Col className="dash-layer">
                    <Col>
                      <span className="dash_white_nameFont">0元起拍</span>
                      <br></br>
                      <span className="dash_white_linkFont" href="/">
                        点击查看 {">"}
                      </span>
                    </Col>
                  </Col>
                </Col>
              </Row>
              <Row className="dash_row2">
                <Col
                  className="dash-redbox"
                  style={{
                    backgroundImage: `url(${ButtonBGThree})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right",
                  }}
                  onClick={() => {
                    // toastEvent("此服务展示未能开通，请联系客服");
                    navigate("/bidding/empty_room?name=等级拍卖", {
                      replace: true,
                      state: dashboardNavState,
                    })
                  }}
                >
                  <Col className="dash-layer">
                    <Col>
                      <span className="dash_white_nameFont">等级拍卖</span>
                      <br></br>
                      <span className="dash_white_linkFont" href="/">
                        点击查看 {">"}
                      </span>
                    </Col>
                    <Col className="rebBoxCol2"></Col>
                  </Col>
                </Col>

                <Col
                  onClick={() => {
                    navigate("/bidding/room", {
                      replace: true,
                      state: dashboardNavState,
                    })
                  }}
                  className="dash-redbox"
                  style={{
                    backgroundImage: `url(${ButtonBGFour})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right",
                  }}
                >
                  <Col className="dash-layer-blue">
                    <Col>
                      <span className="dash_white_nameFont">高手专区</span>
                      <br></br>
                      <span className="dash_white_linkFont" href="/">
                        点击查看 {">"}
                      </span>
                    </Col>
                    <Col className="rebBoxCol2"></Col>
                  </Col>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
