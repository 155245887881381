import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FormikForm from "../common/formik.form";
import verifyService from "../../services/user.services";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import common from "../../utils/common";
import renderServices from "../../services/render.services";
import Loader from "./../common/loading/loader";

async function onSubmit(data, setFieldError, navigate, setSubmitting) {
  try {
    const response = await verifyService.verify({ ...data });

    if (response) {
      common.sweetAlertCustom({
        title: "通知",
        html: response.data.data,
        showCancelButton: false,
        showConfirmButton: false,
      });
      navigate("/profile");
    }
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors;

      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item]);
        });
      }
    }
  }
  setSubmitting(false);
}

const Verification = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ name: "", ic_number: "" });
  const [verification, setVerification] = useState("");
  const [loading, setLoading] = useState(false);
  const verifySchema = Yup.object({
    name: Yup.string()
      .required("需要填入您的全名")
      .matches(/(\p{Script=Hani})+/gu, "只能输入中文名字"),

    ic_number: Yup.string()
      .required("需要填入您的身份证号")
      // .typeError("身份证号格式必须是数字")
      .test("len", "号码格式错误", function (value) {
        if (value) {
          return value.toString().length >= 10;
        } else {
          return false;
        }
      }),
  });

  useEffect(() => {
    setLoading(true);
    getUser().catch((e) => {
      console.log(e);
    });
  }, []);

  const getUser = async () => {
    const response = await renderServices.userInfo();
    if (response.data.kyc_verification === "true") {
      setVerification("true");
      setData({ name: response.data.name, ic_number: response.data.ic_number });
    } else {
      if (response.data.kyc_verification === "pending") {
        setVerification("pending");
      }
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      {!loading ? (
        <Container>
          <Row className="basic-form">
            <Formik
              initialValues={data}
              validationSchema={verifySchema}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                onSubmit(values, setFieldError, navigate, setSubmitting);
              }}
            >
              {({ setFieldValue, errors, isSubmitting, isValid }) => (
                <>
                  {verification === "true" ? (
                    <Form>
                      <Row className="custom-field">
                        {/* <label htmlFor={name}> {label} </label> */}
                        <Field
                          className="form-control"
                          name="name"
                          type="text"
                          value={data.name}
                          readOnly
                        />
                      </Row>
                      <Row className="custom-field">
                        {/* <label htmlFor={name}> {label} </label> */}
                        <Field
                          className="form-control"
                          name="ic_number"
                          type="text"
                          value={data.ic_number}
                          readOnly
                        />
                      </Row>
                      <div
                        style={{
                          color: "#3B824F",
                          marginTop: "1em",
                          fontSize: "0.9rem",
                        }}
                      >
                        已实名认证
                      </div>
                    </Form>
                  ) : (
                    <Form>
                      {FormikForm.renderInput("name", "请输入您的全名", "text")}
                      {FormikForm.renderInput(
                        "ic_number",
                        "请输入您的身份证号"
                      )}
                      <div
                        style={{
                          color: "#6B98C0",
                          marginTop: "1em",
                          fontSize: "0.9rem",
                        }}
                      >
                        请确认真实有效身份信息再进行认证
                      </div>

                      {FormikForm.renderButton("提交", isSubmitting, isValid)}
                    </Form>
                  )}
                </>
              )}
            </Formik>
          </Row>
        </Container>
      ) : (
        <Loader
          style={{
            position: "fixed",
            left: "44%",
            top: "44%",
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Verification;
