import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import MeterImage from "./../../assets/images/grade.png";
import Text from "../common/text";
import userServices from "../../services/user.services";
import {
  Metter0,
  Metter100,
  Metter200,
  Metter300,
  Metter400,
  Metter500,
  Metter668,
  // Metter700,
  // Metter800,
  // Metter999,
} from "./../../assets/images/index";

const Meter = () => {
  const [score, setScore] = useState(0);
  const [meterImg, setMeterImg] = useState({});
  useEffect(() => {
    validateProfile();
  });
  let today = new Date();
  let date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const validateProfile = async () => {
    const { data: response } = await userServices.getProfile();
    const profile = response.data;
    setScore(parseInt(profile.trust_meter));
  };

  const getImage = (score) => {
    switch (score) {
      case 0:
        return Metter0;
        break;
      case 100:
        return Metter100;
        break;
      case 200:
        return Metter200;
        break;
      case 300:
        return Metter300;
        break;
      case 400:
        return Metter400;
        break;
      case 500:
        return Metter500;
        break;
      default:
        return Metter668;
        break;
    }
  };

  return (
    <React.Fragment>
      <Container className="container-full-white" style={{ height: "100%" }}>
        <Row className="meter-main g-0">
          <Row className="meter g-0">
            <img src={getImage(score)} alt="" />
            <Row
              className="g-0"
              style={{
                minWidth: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Text
                value={score >= 500 ? "良好" : "不良好"}
                className="text-center"
              />
              <Text value={"更新时间：" + date} className="text-center" />
            </Row>
          </Row>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Meter;
