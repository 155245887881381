import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { useParams, useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import CountDownCustom from "../common/countdown.custom";
import Pin from "../../components/common/pin";
import _ from "lodash";
import Loader from "./../common/loading/loader";

import {
  Banner,
  PayIcon1,
  PayIcon2,
  PayIcon3,
  PayIcon4,
  PayIcon5,
} from "../../assets/images";
import { detectOverflow } from "@popperjs/core";
import renderServices from "../../services/render.services";
// import "react-responsive-carousel/lib/styles/carousel.min.css"

const PayDeposit = () => {
  const { id } = useParams();
  const [navState] = useState({ navState: `/deposit/pay/${id}` });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [product, setProduct] = useState({});
  const [functionList] = useState({
    data: [
      {
        nameGrey: "交易",
        nameRed: "担保",
        icon: PayIcon2,
      },
      {
        nameGrey: "资质",
        nameRed: "授权",
        icon: PayIcon3,
      },
      {
        nameGrey: "7天",
        nameRed: "退货",
        icon: PayIcon4,
      },
      {
        nameGrey: "全国",
        nameRed: "包邮",
        icon: PayIcon5,
      },
    ],
  });
  const [info, setInfo] = useState({ sub_image: [] });
  const [optionList, setOptionList] = useState([]);
  const [merchant, setMerchant] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    localStorage.setItem("depositPay", window.location.pathname);
    localStorage.setItem("depositState", JSON.stringify(state));
    // Justin
    if (state) {
      if (state.data.merchant_name) {
        localStorage.setItem("depositData", JSON.stringify(state));
      }
    }
    setLoading(true);
    if (!state) {
      navigate("/");
    } else {
      getBidInfo();
    }
    // setLoading(true);
    // if (!id || !state) {
    //   navigate(-1);
    // } else {
    //   console.log("have either id or state ", id, state);
    //   getBidInfo();
    // }
    // Justin end
  }, [data]);

  const openPin = () => {
    if (_.isEmpty(localStorage.getItem("trade_pin"))) {
      state.navState = navState.navState;
      state.previousState = navState.navState;
      navigate("/set_pin", {
        replace: true,
        state: state,
        // previousState: state,
      });
    }

    if (info.deposit_status) {
      navigate("/bidding/process", {
        replace: true,
        state: { ...info, ...navState, oldState: state },
      });
    } else {
      setData({
        pin: true,
        name: `请输入支付密码`,
        api: "/bid_deposit",
        link: "/deposit/pay/:id",
        bid_id: info.bid_id,
        method: "post",
        type: "deposit",
        navState,
      });
    }
  };
  const closePin = () => {
    setData({
      pin: false,
    });
  };

  const getBidInfo = async () => {
    let product = state.data;
    let payload = "";
    if (product.bid_id) {
      payload = {
        id: product.bid_id,
        code: localStorage.getItem("pin"),
      };
    } else {
      const depositData = JSON.parse(localStorage.getItem("depositData"));
      product = depositData.data;
      payload = {
        id: product.bid_id,
        code: localStorage.getItem("pin"),
      };
    }

    try {
      const { data: response } = await renderServices.getBidInfo(payload);
      setInfo({ ...response, bid_id: product.bid_id });

      setOptionList([
        {
          amount: `￥${product.start_price}`,
          name: "起拍价",
        },
        {
          amount: `￥${product.bid_price}`,
          name: "加价幅度",
        },
        {
          amount: `￥${product.reserve_price}`,
          name: "保证金",
        },
        {
          amount: `￥${product.warranty_price}`,
          name: "保留价",
        },
      ]);
      setProduct(state.data);
      setMerchant({
        merchant_name: product.merchant_name,
        merchant_image: product.merchant_image,
        product_description: product.product_description,
        product_name: product.product_name,
      });
      setLoading(false);
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        navigate("/bidding/room");
      }
    }
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader style={{ minHeight: "calc(100vh - 70px)" }} />
      ) : (
        <div className="main_noBorder2">
          <Carousel
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            autoPlay
            className="paydeposit_display"
          >
            {info.sub_image.map((item) => {
              return <img src={item} className="paydeposit_display" alt="" />;
            })}
            {/* <img src={info.sub_image} className="paydeposit_display" alt="" /> */}
            {/* <img src={Banner} className="paydeposit_display" alt="" />
          <img src={Banner} className="paydeposit_display" alt="" /> */}
          </Carousel>
          <div
            className="d-flex paydeposit_countdown"
            style={{ padding: "0.5em 1em" }}
          >
            <Col className="d-flex paydeposit_countdown_word col-8">
              <div>
                {info.start_status === "false" ? `距离结束：` : `距离开始：`}
                <CountDownCustom
                  date={info.countdown}
                  completeText={
                    info.start_status === "true" ? "进入拍卖" : "已经完毕"
                  }
                  action={() => {
                    getBidInfo();
                    // window.location.reload();
                  }}
                ></CountDownCustom>
              </div>
            </Col>
            <Col
              className="col-4"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <NavLink
                state={{ navState: "/deposit/pay/" + info.bid_id }}
                to="/vip"
                className="non-decoration"
                replace
              >
                <div className="auction_listing_vipLv">VIP {info.min_vip}</div>
              </NavLink>
              {/* <NavLink to="/vip" className="non-decoration">
                <div className="auction_listing_vipLv">VIP {info.min_vip}</div>
              </NavLink> */}
            </Col>
          </div>
          <div className="paydeposit_body">
            <Container className="paydeposit-body1">
              <Row>
                <div className="paydeposit_productName">{info.name}</div>
              </Row>
              <Row className="paydeposit_rowPadding">
                <Col className="col-4">
                  <div className="paydeposit_timer1">结束时间：</div>
                </Col>
                <Col className="col-6">
                  <div className="paydeposit_timer2">{info.end_time}</div>
                </Col>
                <Col className="col-2">
                  <div className="paydeposit_roomId">{info.room_id}</div>
                </Col>
              </Row>
              <Row className="paydeposit_rowPadding">
                <Col className="col-4">
                  <div className="paydeposit_timer1">参与人数：</div>
                </Col>
                <Col className="col-8">
                  <div className="paydeposit_timer2">
                    {info.participant_number}
                  </div>
                </Col>
              </Row>
              <NavLink to="/tnc" className="non-decoration">
                <Row className="paydeposit_announce_topBorder">
                  <Col className="d-flex">
                    <div className="darkgrey-font">拍卖</div>
                    <div className="red-font">规则</div>
                    <img
                      className="paydeposit_announce_pic"
                      src={PayIcon1}
                      alt=""
                    />
                  </Col>
                  <Col className="paydeposit_announce_link">
                    <span href="/" className="mediumgrey-font">
                      马上阅读 {">"}
                    </span>
                  </Col>
                </Row>
              </NavLink>
              <Row className="paydeposit_option_row">
                {optionList.map((item, index) => (
                  <Col
                    key={`option-list-${index}`}
                    className="paydeposit_option_col"
                  >
                    <div className="red-font">{item.amount}</div>
                    <div className="darkgrey-font">{item.name}</div>
                  </Col>
                ))}
              </Row>
            </Container>
            <Container className="paydeposit-body2">
              <Row className="g-0" style={{ padding: "0em 1em" }}>
                {functionList.data.map((listing, index) => (
                  <Col
                    key={`function-list-${index}`}
                    className="d-flex paydeposit_function_col"
                  >
                    <img
                      src={listing.icon}
                      className="paydeposit_function_pic"
                      alt=""
                    />
                    <div className="d-flex paydeposit_function_wording">
                      <div className="darkgrey-font">{listing.nameGrey}</div>
                      <div className="red-font">{listing.nameRed}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
            <Container className="paydeposit-body3">
              <Row>
                <Col className="col-2">
                  <img
                    className="paydeposit_supply_pic"
                    src={merchant.merchant_image}
                    alt=""
                  />
                </Col>
                <Col>
                  <Col className="paydeposit_supply_name">
                    {info.merchant_name}
                  </Col>
                  <Col className="col-3 paydeposit_supply_lv">平台拍卖</Col>
                </Col>
              </Row>
              <Row>
                <div className="paydeposit_supply_content">拍品资料:</div>
                <div className="paydeposit_supply_content">
                  {/* {merchant.product_name} */}
                </div>
                <div
                  className="paydeposit_supply_content"
                  dangerouslySetInnerHTML={{
                    __html: info.product_description || "",
                  }}
                ></div>
              </Row>
            </Container>
          </div>
          {!info.deposit_status ? (
            <>
              <div onClick={openPin} className="paydeposit_payButtonv2">
                <span
                  style={{
                    bottom: "0",
                    position: "absolute",
                    top: "6px",
                    left: "0",
                    right: "0",
                    fontSize: "0.5em",
                    color: "#f9d080",
                  }}
                >
                  保证金￥{info.reserve_price}
                </span>

                <div className="paydeposit_payButton_word">支付保证金</div>
              </div>
            </>
          ) : info.bid_status === "bidding" ? (
            <div onClick={openPin} className="paydeposit_payButton">
              <div className="paydeposit_payButton_word">我要出价</div>
            </div>
          ) : (
            <div className="paydeposit_payButton_disable">
              <div className="paydeposit_payButton_word_disable">我要出价</div>
            </div>
          )}
        </div>
      )}
      <Pin data={data} closePin={closePin} />
    </React.Fragment>
  );
};

export default PayDeposit;
